import { useEffect } from 'react'
import { AuthedStatusEnum } from '@/config'
import { RoutePathEnum } from '@/constants/routePath'
import useClearEpfStatus from '@/hooks/useClearEpfStatus'

export const useCheckEpfJourney = ({
  pathname,
  authStatus,
}: {
  pathname: string
  authStatus: AuthedStatusEnum
}) => {
  const handleClearEpfStatus = useClearEpfStatus()

  useEffect(() => {
    /**
     * if user is on EPF purchasing journey but navigiate to pages out of thee journey,
     * remove epf token, reset AuthStatus to LoggedIn
     */
    if (
      ![
        RoutePathEnum.ROOT,
        RoutePathEnum.PROFILE_SETUP,
        RoutePathEnum.PROFILE_ECDD,
        RoutePathEnum.ORDER_PURCHASE_FORM,
        RoutePathEnum.ORDER_REDEMPTION_FORM,
        RoutePathEnum.ORDER_SWITCHING_FORM,
        // RoutePathEnum.ORDER_PURCHASE_RECEIPT,
      ].includes(pathname as unknown as RoutePathEnum) &&
      [
        AuthedStatusEnum.EpfPurchasing,
        AuthedStatusEnum.EpfRedemption,
        AuthedStatusEnum.EpfSwitching,
      ].includes(authStatus)
    )
      handleClearEpfStatus()
  }, [pathname, authStatus])
}

export default useCheckEpfJourney
