import ErrorMessageComp from '@/components/ErrorMessageComp'
import FundCard from '@/components/FundCard'
import InviewportChecker from '@/components/InfintiFeedLoad/InviewportChecker'
import Loading from '@/components/Loading'
import FundDetailsCard from '@/components/PortfolioFundDetails/FundDetailsCard'
import FundDetailsModal from '@/components/PortfolioFundDetails/FundDetailsModal'
import RiskProfileStatus from '@/components/RiskProfileStatus'
import { RoutePathEnum } from '@/constants/routePath'
import { useIsInViewport } from '@/hooks/InfintiFeedLoad/useIsInViewport'
import useBreakpoint from '@/hooks/useBreakPoint'
import useGoExternalEpf from '@/hooks/useGoExternalEpf'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import { PortfolioFund } from '@/utils/portfolioFund'
import { Button } from '@mui/material'
import { MainBody, useModal } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import useInitData from './hooks/useInitData'
import InvestmentCategoryTabs from '@/components/InvestmentCategoryTabs'
import TechnicalErrorComp from '@/components/TechnicalErrorComp'

export const Portfolio = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['portfolioPage', 'common'])
  const { open, modalClose, modalOpen } = useModal()
  const { isMdAbove } = useBreakpoint()
  const goExternalEpf = useGoExternalEpf()

  const { isValidUserStatus, handleCheckUserStatus } = useHandleUserStatus()

  const { isInViewport, setRef } = useIsInViewport<HTMLDivElement>()

  const {
    isLoading,
    isLoadingPage,
    isError,
    isErrorPage,
    feeds,
    tabs,
    ...riskProfileData
  } = useInitData()

  const [selectedFund, setSelectedFund] = useState<PortfolioFund | undefined>(
    undefined
  )

  const onCloseFundDetails = () => {
    setSelectedFund(undefined)
    modalClose()
  }

  const onFundCardClick = (fund: PortfolioFund) => {
    if (selectedFund?.code === fund.code) {
      setSelectedFund(undefined)
      return
    }
    setSelectedFund(fund)
  }

  useEffect(() => {
    //auto close fund details on tab click --
    onCloseFundDetails()
  }, [tabs.tabType])

  useEffect(() => {
    console.log('isInViewport: ', isInViewport)
    if (isInViewport) feeds.handleShowMore()
  }, [isInViewport])

  useEffect(() => {
    if (!!selectedFund && !isMdAbove) {
      /**
       * if has fund selected and resize screen from md to mobile:
       * open the modal
       */
      //
      modalOpen()
      return
    }
    /** else, close the modal */
    modalClose()
  }, [selectedFund, isMdAbove])

  const onCashIn = () => {
    handleCheckUserStatus({})
    if (!isValidUserStatus) return

    if (!!selectedFund?.isEPFFund) {
      goExternalEpf()
      return
    }
    navigate(RoutePathEnum.ORDER_PURCHASE_FORM, {
      state: { selectedFunds: [{ ...selectedFund, amount: 0 }] },
    })
  }

  const onExploreNewFund = () => {
    handleCheckUserStatus({})
    if (!isValidUserStatus) return
    navigate(RoutePathEnum.ORDER_PURCHASE)
  }

  useEffect(() => {
    if (isErrorPage) navigate(RoutePathEnum.ERROR)
  }, [isErrorPage])

  return isLoadingPage ? (
    <Loading isLoadingPage />
  ) : (
    <>
      <RiskProfileStatus {...riskProfileData} />
      <MainBody>
        <h3 className='col-span-full text-xl font-bold leading-7 mb-0'>
          {t('my-investment-portfolio')}
        </h3>
        <InvestmentCategoryTabs {...tabs} />

        {isLoading ? (
          <Loading className='min-h-[calc(100vh-450px)]' />
        ) : isError ? (
          <TechnicalErrorComp />
        ) : (
          <div className='col-span-full grid grid-cols-3 gap-4'>
            <div className='col-span-full md:col-span-2'>
              <div className='flex flex-col gap-4'>
                {!!feeds.displayFeeds.length ? (
                  feeds.displayFeeds.map((item) => (
                    <FundCard
                      key={item.code}
                      {...item}
                      className='h-full'
                      onCardClick={() => onFundCardClick(item)}
                      isSelected={selectedFund?.code === item.code}
                    />
                  ))
                ) : (
                  // TODO: pending for actual text --
                  <ErrorMessageComp
                    className='col-span-full min-h-[calc(100vh-450px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center gap-2 [&>h1]:!mb-0 [&>h1]:!text-xl [&>h1]:!font-normal [&>h1]:!text-pi-gray-1'
                    type={t('oops-no-funds-available', { ns: 'portfolioPage' })}
                    message={t('please-check-back-later', {
                      ns: 'portfolioPage',
                    })}
                    displayIcon={false}
                  />
                )}
              </div>
            </div>
            <div className='hidden md:!block'>
              <FundDetailsCard
                fundDetails={selectedFund}
                onCloseFund={onCloseFundDetails}
                onCashIn={onCashIn}
              />
            </div>
          </div>
        )}
        <InviewportChecker
          ref={setRef}
          isOffset={!!feeds.displayFeeds.length}
        />
        <Button
          variant='contained'
          fullWidth
          className='col-span-full mb-6 md:col-start-2 md:col-span-6'
          onClick={onExploreNewFund}
        >
          {t('explore-new-fund', { ns: 'common' })}
        </Button>
        <FundDetailsModal
          open={open}
          onClose={onCloseFundDetails}
          onCashIn={onCashIn}
          fundDetails={selectedFund}
          modalClose={modalClose}
          isValidUserStatus={isValidUserStatus}
          className='md:!hidden'
        />
      </MainBody>
    </>
  )
}

export default Portfolio
