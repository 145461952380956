import { useQueryFundList } from '@/api/commonApi'
import Loading from '@/components/Loading'
import TwoSideText from '@/components/TwoSideText'
import { RoutePathEnum } from '@/constants/routePath'
import { calTotalAmount, insufficientBalanceCheck } from '@/utils'
import {
  AccountStatusEnum,
  FundInformation,
  OrderFundDto,
  OrderFundResDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import {
  ButtonWithLoading,
  DEF_DECIMAL,
  formattedAmount,
  ResponseError,
  ResponseErrorProps,
  useModal,
} from '@pimy-b2cweb/frontend-lib'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  RedemptionOrderState,
  SetRedemptionFundsProps,
} from '../../hooks/useRedemptionOrderReducer'
import FundsComp, { FundsCompItemErr } from './components/FundsComp'
import InsufficientBalanceModal, {
  TrxTypeEnum,
} from '@/components/InsufficientBalanceModal'
import SubaccountInvalidModal, {
  ActionTypeEnum,
} from '@/components/SubaccountInvalidModal'

export interface CashOutFund extends OrderFundResDto, FundInformation {}

export interface CashOutAmountFormProps {
  funds: CashOutFund[]
  totalAmount: number
}

export interface CashOutAmountProps
  extends Pick<RedemptionOrderState, 'subaccountStatus'> {
  cashOutFunds: OrderFundResDto[]
  orderFormError: ResponseErrorProps | undefined
  onSubmitAmount?: (data: SetRedemptionFundsProps) => void
  goNext: () => void
}

const checkInsufficientBalance = (
  cashOutFunds: CashOutAmountFormProps['funds']
) => {
  return cashOutFunds.some(insufficientBalanceCheck)
}

const CASH_OUT_INVALID_SUB_ACC_STATUS = [
  AccountStatusEnum.FROZEN,
  AccountStatusEnum.CLOSED,
]

const CashOutAmount = ({
  cashOutFunds,
  orderFormError,
  onSubmitAmount,
  goNext,
  subaccountStatus,
}: CashOutAmountProps) => {
  const { t } = useTranslation(['redemptionPage', 'common'])
  const navigate = useNavigate()

  const insufficientBalanceModal = useModal()
  const subaccountInvalidModal = useModal()

  const {
    data: fundList,
    isLoading: isLoadingFL,
    isError: isErrorFL,
  } = useQueryFundList({ enabled: true })

  useEffect(() => {
    if (isErrorFL) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isErrorFL])

  const funds: CashOutFund[] = useMemo(() => {
    if (!cashOutFunds?.length || !fundList?.length) return []
    return convertCashOutFund({
      cashOutFunds,
      fundList,
    })
  }, [cashOutFunds, fundList])

  const { control, handleSubmit, setValue, watch } =
    useForm<CashOutAmountFormProps>({
      mode: 'onChange',
      defaultValues: {
        funds: funds,
        totalAmount: 0,
      },
    })

  useEffect(() => {
    const _totalAmount = calTotalAmount(watch('funds'))
    if (watch('totalAmount') !== _totalAmount)
      setValue('totalAmount', _totalAmount)
  }, [watch('funds')])

  const onSubmit = (data: CashOutAmountFormProps) => {
    console.log(data)

    /* Subaccount status check */
    if (
      CASH_OUT_INVALID_SUB_ACC_STATUS.includes(
        subaccountStatus as AccountStatusEnum
      )
    ) {
      subaccountInvalidModal.modalOpen()
      return
    }

    const _hasInsufficientBalance = checkInsufficientBalance(data.funds)
    if (_hasInsufficientBalance) {
      insufficientBalanceModal.modalOpen()
      return
    }

    if (!!onSubmitAmount) {
      const totalAmount = calTotalAmount(data.funds)

      onSubmitAmount({
        funds: data.funds as OrderFundDto[],
        amount: totalAmount,
      })
    }
    goNext()
  }

  const onContinue = useCallback(() => {
    const cashOutFunds = watch('funds').map((fund) =>
      insufficientBalanceCheck(fund)
        ? { ...fund, amount: fund.availableAmount }
        : fund
    )
    setValue('funds', cashOutFunds)
  }, [watch('funds')])

  return isLoadingFL ? (
    <Loading isLoadingPage />
  ) : (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='min-h-[calc(100vh-202px)] md:min-h-0 flex flex-col'
      >
        <div className='grow'>
          <h1 className='font-bold'>{t('cash-out', { ns: 'common' })}</h1>
          {!!orderFormError && (
            <ResponseError>
              {t(orderFormError.i18nKey, {
                ns: orderFormError.ns,
              })}
            </ResponseError>
          )}
          <Controller
            name='funds'
            control={control}
            rules={{
              validate: (value) => {
                const _err = value.reduce(
                  (
                    acc,
                    { code, amount, maxAmount, minAmount, availableAmount }
                  ) => {
                    const message =
                      amount <= 0
                        ? 'Required'
                        : amount < minAmount
                        ? 'smaller-than-min-redmp-amount'
                        : amount >
                          Number(
                            Math.min(maxAmount, availableAmount).toFixed(
                              DEF_DECIMAL
                            )
                          )
                        ? 'larger-than-max-redmp-amount'
                        : undefined
                    return !!message ? [...acc, { code, message }] : acc
                  },
                  [] as FundsCompItemErr[]
                )
                return !_err.length || JSON.stringify(_err)
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <FundsComp {...field} error={error} className='mb-9' />
            )}
          />

          <p className='text-pi-gray-2 mb-14'>{t('redemption-note')}</p>
        </div>

        <div className='p-6 -m-6 shadow-[0_-2px_6px_0_rgba(0,0,0,0.1)] bg-white sm:!bg-transparent'>
          <TwoSideText
            className='text-sm font-bold items-end mb-4'
            left={t('total-cash-out')}
            right={
              <span className='text-[28px] leading-[36px]'>
                {formattedAmount({ amount: watch('totalAmount') })}
              </span>
            }
          />
          <ButtonWithLoading
            type='submit'
            fullWidth
            variant='contained'
            size='large'
            className='mb-4 sm:mb-2'
          >
            {t('Continue', { ns: 'common' })}
          </ButtonWithLoading>
        </div>
      </form>
      <InsufficientBalanceModal
        {...insufficientBalanceModal}
        trxType={TrxTypeEnum.CASH_OUT}
        onContinue={onContinue}
      />
      <SubaccountInvalidModal
        {...subaccountInvalidModal}
        action={ActionTypeEnum.REDEMPTION}
      />
    </>
  )
}

export default CashOutAmount

const convertCashOutFund = ({
  cashOutFunds,
  fundList,
}: {
  cashOutFunds?: OrderFundResDto[]
  fundList?: FundInformation[]
}): CashOutFund[] => {
  if (!cashOutFunds || !cashOutFunds.length || !fundList || !fundList.length)
    return [] as CashOutFund[]

  return cashOutFunds.reduce((acc, item) => {
    const fund = fundList.find(({ code }) => code === item.fundCode)
    return !!fund ? [...acc, { ...fund, ...item }] : acc
  }, [] as CashOutFund[])
}
