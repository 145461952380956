import {
  ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper,
  MenuItem,
  MenuList,
} from '@mui/material'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ReactComponent as UserIco } from '../assets/user.svg'
import { ReactComponent as GiftIco } from '../assets/gift-ico.svg'
import { RoutePathEnum } from '@/constants/routePath'
import useHandleSignOut from '@/hooks/useHandleSignOut'
import Divider from '@/components/Divider'
import CopyToClipboard from '@/components/CopyToClipboard'
import { ReferralInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'

const PROFILE_MENU_ID = 'profile-menu'

export const ProfileMenu = memo(
  ({ referralCode }: { referralCode?: string }) => {
    const { t } = useTranslation('header')
    const navigate = useNavigate()
    const anchorRef = useRef<HTMLButtonElement>(null)
    const handleSignOut = useHandleSignOut()

    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const handleToggle = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
      setOpen((previousOpen) => !previousOpen)
    }

    const canBeOpen = useMemo(() => open && Boolean(anchorEl), [open])
    const id = useMemo(
      () => (canBeOpen ? PROFILE_MENU_ID : undefined),
      [canBeOpen]
    )
    const prevOpen = useRef(open)

    const handleClose = () => {
      setOpen(false)
    }

    const handleListKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Tab') {
        event.preventDefault()
        handleClose()
      } else if (event.key === 'Escape') {
        handleClose()
      }
    }

    useEffect(() => {
      window.addEventListener('scroll', handleClose)
      return () => window.removeEventListener('scroll', handleClose)
    }, [])

    useEffect(() => {
      // return focus to the button when we transitioned from open -> !open
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus()
      }

      prevOpen.current = open
    }, [open])

    return (
      <div>
        <IconButton
          id='profile-button'
          className='cursor-pointer w-6 h-6 p-0'
          ref={anchorRef}
          aria-describedby={id}
          aria-haspopup='menu'
          onClick={handleToggle}
        >
          <UserIco />
        </IconButton>
        {!!open && (
          <Popper
            open={open}
            id={id}
            anchorEl={anchorEl}
            placement='bottom-end'
            transition
            disablePortal
            className='!mt-2'
          >
            {({ TransitionProps }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'left bottom',
                }}
              >
                <Paper className='bg-pi-navy-blue text-white px-2 rounded-xl'>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id='profile-menu'
                      aria-labelledby='profile-button'
                      onKeyDown={handleListKeyDown}
                    >
                      {!!referralCode && (
                        <ReferralCode referralCode={referralCode} />
                      )}
                      <MenuItem
                        onClick={() => {
                          handleClose()
                          navigate(RoutePathEnum.PROFILE_EDIT)
                        }}
                        className='hover:!bg-pi-sky-blue-1/20 justify-end rounded-md'
                      >
                        {t('manage-profile')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose()
                          navigate(RoutePathEnum.ERROR) // TODO: to be replaced with actual path
                        }}
                        className='hover:!bg-pi-sky-blue-1/20 justify-end rounded-md'
                      >
                        {t('feedback')}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          handleClose()
                          handleSignOut()
                        }}
                        className='hover:!bg-pi-sky-blue-1/20 justify-end rounded-md'
                      >
                        {t('log-out')}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        )}
      </div>
    )
  }
)

export default ProfileMenu

const ReferralCode = ({
  referralCode,
}: Pick<ReferralInformation, 'referralCode'>) => {
  const { t } = useTranslation('header')
  return (
    <>
      <div className='px-4 font-normal pb-2'>
        <div className='flex flex-row gap-4 justify-between'>
          <div className='font-bold select-none'>{`${t(
            'referral-code'
          )}:`}</div>
          <GiftIco className='w-6 h-6' />
        </div>
        <div className='flex flex-row justify-between'>
          <div className='font-normal'>{referralCode}</div>
          <CopyToClipboard text={referralCode} />
        </div>
      </div>
      <Divider className='-mx-2 h-0.5 bg-pi-sky-blue-1/20' />
    </>
  )
}
