import ErrorMessageComp from '@/components/ErrorMessageComp'
import FundCard from '@/components/FundCard'
import InvestmentCategoryTabs from '@/components/InvestmentCategoryTabs'
import Loading from '@/components/Loading'
import { RoutePathEnum } from '@/constants/routePath'
import { InvestmentCategoryEnum } from '@/enums/InvestmentCategoryEnum'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import useInvestmentPageContent from '@/hooks/useInvestmentPageContent'
import { MainBody, useModal } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CashInInvestmentFooter from './components/CashInInvestmentFooter'
import LeaveCashInModal from './components/LeaveCashInModal'

const InvestedFunds = () => {
  const { t } = useTranslation([
    'investedFundsPage',
    'investmentPage',
    'common',
  ])

  const navigate = useNavigate()

  const { isLoading, isError, feeds, tabs, fundSelectionUtils } =
    useInvestmentPageContent()
  const {
    selectedFundCodes,
    selectedFundCount,
    onSelectFund,
    onDeselectFund,
    onCheckOut,
  } = fundSelectionUtils

  const leaveCashInModal = useModal()

  const { isValidUserStatus, handleCheckUserStatus } = useHandleUserStatus()

  useEffect(() => {
    if (isError) navigate(RoutePathEnum.ERROR)
  }, [isError])

  const onExploreNewFunds = () => {
    !!selectedFundCount
      ? leaveCashInModal.modalOpen()
      : navigate(RoutePathEnum.ORDER_PURCHASE)
  }

  const onCashIn = () => {
    handleCheckUserStatus({})
    if (!isValidUserStatus) return

    onCheckOut()
  }
  const useSelect = useMemo(
    () => tabs.tabType === InvestmentCategoryEnum.CASH,
    [tabs.tabType]
  )

  return (
    <>
      <MainBody>
        <h3 className='col-span-full text-xl font-bold leading-7 mb-0'>
          {t('please-select-fund-to-cash-in')}
        </h3>

        <InvestmentCategoryTabs {...tabs} />

        {isLoading ? (
          <Loading isLoadingPage={true} />
        ) : (
          <>
            <div className='col-span-full'>
              <div className='flex flex-col gap-4'>
                {!!feeds.displayFeeds.length ? (
                  feeds.displayFeeds.map((fund) => {
                    const isSelected =
                      useSelect && selectedFundCodes.includes(fund.code)
                    const onCardClick = useSelect
                      ? () =>
                          isSelected
                            ? onDeselectFund(fund.code)
                            : onSelectFund(fund)
                      : undefined
                    return (
                      <FundCard
                        key={fund.code}
                        {...fund}
                        className='h-full'
                        onCardClick={onCardClick}
                        isSelected={isSelected}
                      />
                    )
                  })
                ) : (
                  // TODO: pending for actual text --
                  <ErrorMessageComp
                    className='col-span-full min-h-[calc(100vh-450px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center gap-2 [&>h1]:!mb-0 [&>h1]:!text-xl [&>h1]:!font-normal [&>h1]:!text-pi-gray-1'
                    type={t('oops-no-funds-available', {
                      ns: 'investmentPage',
                    })}
                    message={t('please-check-back-later', {
                      ns: 'investmentPage',
                    })}
                    displayIcon={false}
                  />
                )}
              </div>
            </div>
          </>
        )}
        <LeaveCashInModal {...leaveCashInModal} />
      </MainBody>
      <CashInInvestmentFooter
        {...fundSelectionUtils}
        tabType={tabs.tabType}
        onExploreNewFunds={onExploreNewFunds}
        onCashIn={onCashIn}
      />
    </>
  )
}

export default InvestedFunds
