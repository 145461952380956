import { Button } from '@mui/material'
import { useModal } from '@pimy-b2cweb/frontend-lib'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Loading from '@/components/Loading'
import FundDetailsModal from '@/components/PortfolioFundDetails/FundDetailsModal'
import TwoSideText from '@/components/TwoSideText'
import { RoutePathEnum } from '@/constants/routePath'
import { InvestmentCategoryEnum } from '@/enums/InvestmentCategoryEnum'
import { PurchaseTypeEnum } from '@/enums/purchaseTypeEnums'
import useGoExternalEpf from '@/hooks/useGoExternalEpf'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import ISAFAlertBar from '@/Routes/pages/Dashboard/components/ISAFAlertBar'
import { PortfolioFund } from '@/utils/portfolioFund'
import { ReactComponent as CashInIco } from './assets/cash-in.svg'
import { ReactComponent as CashOutIco } from './assets/cash-out.svg'
import { ReactComponent as InvBanner } from './assets/inv-banner.svg'
import AccountBalanceCard, {
  AccountTypeEnum,
} from './components/AccountBalanceCard'
import AccountStatus from './components/AccountStatus'
import FunctionCard from './components/FunctionCard'
import InvestmentsByCategory from './components/InvestmentsByCategory'
import MarketInsigntBanner from './components/MarketInsigntBanner'
import SectionHeading from './components/SectionHeading'
import useInitData from './hooks/useInitData'

export const Dashboard = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(['dashboardPage', 'common'])

  const { portfolioData, allFunds, epfFunds, cashFunds, isLoading } =
    useInitData()
  const goExternalEpf = useGoExternalEpf()

  const { isafStatus, isValidUserStatus, handleCheckUserStatus } =
    useHandleUserStatus()

  const fundDetailModal = useModal()
  const onCloseFundDetails = () => {
    setSelectedFund(undefined)
    fundDetailModal.modalClose()
  }
  const onClickFund = (fund: PortfolioFund) => {
    setSelectedFund(fund)
    fundDetailModal.modalOpen()
  }

  const [selectedFund, setSelectedFund] = useState<PortfolioFund | undefined>(
    undefined
  )

  const onExploreNewFund = () => {
    handleCheckUserStatus({})
    if (!isValidUserStatus) return
    navigate(RoutePathEnum.ORDER_PURCHASE)
  }

  const onClickPurchase = (purchaseType: PurchaseTypeEnum) => {
    handleCheckUserStatus({})
    if (!isValidUserStatus) return
    purchaseType === PurchaseTypeEnum.CashIn
      ? navigate(
          RoutePathEnum.ORDER_PURCHASE_INVESTED_FUNDS
          // , {
          //   state: { category: InvestmentCategoryEnum.CASH },
          // }
        )
      : navigate(RoutePathEnum.ORDER_SWITCHING)
  }

  const onCashIn = () => {
    handleCheckUserStatus({})
    if (!isValidUserStatus) return
    if (!!selectedFund?.isEPFFund) {
      goExternalEpf()
      return
    }
    navigate(RoutePathEnum.ORDER_PURCHASE_FORM, {
      state: { selectedFunds: [{ ...selectedFund, amount: 0 }] },
    })
  }

  return (
    <>
      {isLoading ? (
        <Loading isLoadingPage={true} />
      ) : (
        <>
          <ISAFAlertBar isafStatus={isafStatus} />
          <PostLogonMainBody
            layout='12-10-8'
            className='md:!py-10 [&>div]:!my-0'
          >
            <AccountStatus
              className='mb-6 sm:mb-4 -mt-6 md:mt-auto'
              {...portfolioData}
            />
            <div className='grid grid-cols-2 gap-2 md:!gap-4 mb-6 md:!mb-4'>
              <AccountBalanceCard
                category={AccountTypeEnum.CASH}
                {...portfolioData?.cashGroup}
                numOfFunds={portfolioData?.cashGroup.funds?.length}
                onClickFunds={() =>
                  navigate(RoutePathEnum.PORTFOLIO, {
                    state: { category: InvestmentCategoryEnum.CASH },
                  })
                }
              />
              <AccountBalanceCard
                category={AccountTypeEnum.EPF}
                {...portfolioData?.epfGroup}
                numOfFunds={portfolioData?.epfGroup.funds?.length}
                onClickFunds={() =>
                  navigate(RoutePathEnum.PORTFOLIO, {
                    state: { category: InvestmentCategoryEnum.KWSP_I_INVEST },
                  })
                }
              />
            </div>
            <div className='grid grid-cols-3 justify-items-stretch gap-2 md:!gap-4 mb-10'>
              <FunctionCard
                Icon={CashOutIco}
                title={t('cash-out')}
                onClick={() => navigate(RoutePathEnum.ORDER_REDEMPTION)}
              />
              <FunctionCard
                Icon={CashOutIco}
                title={t('switch')}
                onClick={() => onClickPurchase(PurchaseTypeEnum.Switch)}
              />
              <FunctionCard
                Icon={CashInIco}
                title={t('cash-in', { ns: 'common' })}
                onClick={() => onClickPurchase(PurchaseTypeEnum.CashIn)}
              />
            </div>
            <TwoSideText
              left={<SectionHeading title={t('my-portfolio')} />}
              right={<Link to={RoutePathEnum.PORTFOLIO}>{t('view-all')}</Link>}
              className='mb-4 sm:!mb-6 items-center'
            />
            {!!allFunds?.length ? (
              <div className='mb-4'>
                {!!cashFunds?.length && (
                  <InvestmentsByCategory
                    category={AccountTypeEnum.CASH}
                    funds={cashFunds}
                    onClickFund={onClickFund}
                  />
                )}
                {!!epfFunds?.length && (
                  <InvestmentsByCategory
                    category={AccountTypeEnum.EPF}
                    funds={epfFunds}
                    onClickFund={onClickFund}
                  />
                )}
              </div>
            ) : (
              <InvBanner
                className='min-h-56 w-auto block mx-auto mb-10 cursor-pointer'
                onClick={onExploreNewFund}
              />
            )}
            <Button
              variant='contained'
              fullWidth
              onClick={onExploreNewFund}
              className='mb-6 md:hidden'
            >
              {t('explore-new-fund', { ns: 'common' })}
            </Button>
            <SectionHeading
              title={t('market-insight')}
              className='mb-4 sm:!mb-6'
            />
            <MarketInsigntBanner alt='banner' />
            <FundDetailsModal
              open={fundDetailModal.open}
              onClose={onCloseFundDetails}
              onCashIn={onCashIn}
              fundDetails={selectedFund}
              modalClose={fundDetailModal.modalClose}
              isValidUserStatus={isValidUserStatus}
            />
          </PostLogonMainBody>
        </>
      )}
    </>
  )
}

export default Dashboard
