import { selectAuthSessionState } from '@/stores/auth.selectors'
import { useSelector, useDispatch } from 'react-redux'
import { userStatusModalSessionSlice } from '@/stores/userStatusModal'
import { EcddStatusEnum } from '@/enums/ecddEnums'
import { IsafStatusEnum } from '@/enums/isafEnums'

export const useHandleUserStatus = () => {
  const dispatch = useDispatch()
  const { isafStatus, ecddStatus, isValidUserStatus } = useSelector(
    selectAuthSessionState
  )
  const {
    sessionResetUserStatusModal,
    sessionOpenIsafModal,
    sessionOpenEcddModal,
  } = userStatusModalSessionSlice.actions

  const handleCheckUserStatus = ({
    forceOpenModal,
  }: {
    forceOpenModal?: 'isaf' | 'ecdd'
  }) => {
    if (forceOpenModal === 'isaf' || isafStatus !== IsafStatusEnum.Passed) {
      dispatch(sessionOpenIsafModal())
      return
    }

    if (forceOpenModal === 'ecdd' || ecddStatus !== EcddStatusEnum.Passed) {
      dispatch(sessionOpenEcddModal())
      return
    }

    dispatch(sessionResetUserStatusModal())
  }

  return {
    isafStatus,
    ecddStatus,
    isValidUserStatus,
    handleCheckUserStatus,
  }
}

export default useHandleUserStatus
