import ErrorMessageComp from '@/components/ErrorMessageComp'
import InviewportChecker from '@/components/InfintiFeedLoad/InviewportChecker'
import Loading from '@/components/Loading'
import RiskProfileStatus from '@/components/RiskProfileStatus'
import TransactionHistoryItemCard from '@/components/TransactionHistoryItemCard'
import useIsInViewport from '@/hooks/InfintiFeedLoad/useIsInViewport'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import { getTransactionDetails } from '@/utils/transactionHistory'
import { Chip } from '@mui/material'
import { useModal } from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import TransactionFilterModal from './TransactionFilterModal'
import { ReactComponent as XCircleIco } from './assets/x-circle.svg'
import FilterButton from './components/FilterButton'
import LoadingComp from './components/LoadingComp'
import useInitData from './hooks/useInitData'
import { useNavigate } from 'react-router-dom'
import { RoutePathEnum } from '@/constants/routePath'
import { TransactionFilterFormProps } from './hooks/useTransactionFilterForm'
import ScrollToTop from '@/components/ScrollToTop'

export const TransactionHistory = () => {
  const { t } = useTranslation('transactionHistoryPage')

  const navigate = useNavigate()
  const { isInViewport, setRef } = useIsInViewport<HTMLDivElement>()

  const { modalOpen, modalClose, ...useModalRest } = useModal()

  const {
    formMethods,
    filters,
    onSubmitForm,
    onClearFilter,
    transactionDetails,
    fundNamesMapping,
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    isFetchingNextPage,
    riskProfileName,
    profitLoss,
    totalAssetsAmt,
  } = useInitData()

  useEffect(() => {
    console.log('isInViewport: ', isInViewport)
    if (isInViewport) fetchNextPage()
  }, [isInViewport])

  useEffect(() => {
    if (isError) navigate(RoutePathEnum.ERROR)
  }, [isError])

  const onSubmitFilters = (data: TransactionFilterFormProps) => {
    onSubmitForm(data)
    modalClose()
  }

  return (
    <>
      <RiskProfileStatus
        riskProfileName={riskProfileName}
        totalAssetsAmt={totalAssetsAmt}
        profitLoss={profitLoss}
      />
      <PostLogonMainBody layout='12-10-8'>
        <div className='col-span-full flex flex-row justify-between items-center mb-6'>
          <h3 className=' text-xl font-black leading-7 m-0'>
            {t('transaction-history')}
          </h3>
          <FilterButton onClick={modalOpen} className='md:!hidden' />
        </div>
        <div className='col-span-full flex flex-row flex-wrap gap-2 md:mb-4'>
          <FilterButton onClick={modalOpen} className='hidden md:!flex mr-3' />
          {filters.length > 0 && (
            <>
              {filters.map((filter) => (
                <Chip
                  key={filter}
                  label={filter}
                  className='!rounded-full border border-solid border-pi-gray-3'
                  size='small'
                  variant='filled'
                  disabled
                />
              ))}
              <Chip
                label='Clear Filter'
                className='!rounded-full bg-pi-sky-blue-1 text-pi-utility-blue border border-solid border-pi-principal-blue hover:bg-pi-maya-blue hover:text-pi-dark-prussian-blue mb-4 md:!mb-0'
                size='small'
                variant='filled'
                deleteIcon={<XCircleIco />}
                onDelete={onClearFilter}
                onClick={onClearFilter}
                clickable
              />
            </>
          )}
        </div>

        {isLoading ? (
          <Loading isLoadingPage={true} />
        ) : transactionDetails.length > 0 ? (
          <>
            <div className='col-span-full flex flex-col gap-4'>
              {transactionDetails.map(({ month, items }) => {
                return (
                  <div key={month}>
                    <div className='text-base text-black font-bold mb-2'>
                      {month}
                    </div>
                    <div className='flex flex-col gap-4'>
                      {items.map(({ dateStr, items: transactions }) => {
                        return (
                          <div key={dateStr}>
                            <div className='text-sm text-pi-gray-2 mb-1'>
                              {dateStr}
                            </div>
                            <div className='flex flex-col gap-2 max-w-[768px] -mx-4 sm:mr-auto sm:ml-0'>
                              {transactions.map((transaction) => {
                                const transactionDetails =
                                  getTransactionDetails(
                                    transaction,
                                    fundNamesMapping
                                  )
                                return (
                                  <TransactionHistoryItemCard
                                    key={transaction.trxRefNo}
                                    {...transactionDetails}
                                  />
                                )
                              })}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
            {isFetchingNextPage ? (
              <LoadingComp className='col-span-full my-4' />
            ) : hasNextPage ? (
              <InviewportChecker
                ref={setRef}
                isOffset={!!transactionDetails.length}
              />
            ) : (
              <p className='text-xs text-center text-pi-gray-3 mt-5'>
                {t('you-have-caught-up')}
              </p>
            )}
          </>
        ) : (
          <ErrorMessageComp
            className='col-span-full min-h-[calc(100vh-450px)] sm:col-start-3 sm:col-span-8 md:col-start-4 md:col-span-6 lg:col-start-5 lg:col-span-4 flex justify-center items-center gap-2 [&>h1]:!mb-0 [&>h1]:!text-base [&>h1]:!font-normal [&>h1]:!text-pi-gray-3 [&>p]:!text-pi-gray-3'
            type={t('no-transaction-history-to-show')}
            message={t('every-transaction-recorded-automatically')}
            displayIcon={false}
          />
        )}
        <FormProvider {...formMethods}>
          <TransactionFilterModal
            modalClose={modalClose}
            onSubmitForm={onSubmitFilters}
            // responseErrorAttrs={responseErrorAttrs}
            isSubmitting={isLoading}
            {...useModalRest}
          />
        </FormProvider>
        <ScrollToTop className='mt-4' />
      </PostLogonMainBody>
    </>
  )
}

export default TransactionHistory
