import { LovItem } from '@/stores/lov'
import { Autocomplete, TextField } from '@mui/material'
import { PostalInformation } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { FormLabel, SelectBoxComp } from '@pimy-b2cweb/frontend-lib'
import { forwardRef, memo } from 'react'
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import NumericTextField from '../NumericTextField'
import { ReactComponent as IconDown } from './assets/ico-down.svg'

export interface PostalInfoFormProps {
  id: string
  error: ControllerFieldState['error']
  onChange: ControllerRenderProps['onChange']
  value: ControllerRenderProps['value']
  stateInfo: LovItem[]
  disabled: boolean
  postalInfo: PostalInformation[]
}

const PostalInfoForm = memo(
  forwardRef<HTMLDivElement, PostalInfoFormProps>(
    ({ onChange, error, value, stateInfo, disabled, id, postalInfo }, ref) => {
      const { t } = useTranslation(['profileInfoPage', 'common'])

      return (
        <>
          <FormLabel
            id={id}
            label={t('post-code')}
            isError={!!error?.message}
            ref={ref}
          >
            <Autocomplete
              value={{
                postalCode: value.postalCode,
                city: value.cityName,
                state: value.state,
              }}
              onChange={(e, newvalue) => {
                onChange({
                  ...value,
                  ...newvalue,
                  cityName: newvalue.city,
                })
              }}
              id={`${id}.auto-complete-post-code`}
              disableClearable
              options={postalInfo}
              getOptionLabel={(option) => option.postalCode}
              isOptionEqualToValue={(option, value) =>
                option.city === value.city &&
                option.postalCode === value.postalCode &&
                option.state === value.state
              }
              popupIcon={<IconDown className='w-6 h-6' />}
              disabled={disabled}
              renderInput={(params) => (
                <NumericTextField
                  {...params}
                  name={`${id}.postalCode`}
                  fullWidth
                  error={!!error}
                  helperText={!!error ? (error?.message as string) : ''}
                  InputProps={{
                    ...params.InputProps,
                    maxLength: 5,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  }}
                />
              )}
            />
          </FormLabel>
          <FormLabel id={`${id}.cityName`} label={t('city')}>
            <TextField type='text' fullWidth value={value.cityName} disabled />
          </FormLabel>
          <FormLabel id={`${id}.state`} label={t('state')} className='!mb-6'>
            <SelectBoxComp
              id={`${id}.state`}
              items={stateInfo}
              value={value.state}
              disabled
              fullWidth
              className='[&>div>div]:!text-pi-gray-1 [&>div>svg]:hidden'
            />
          </FormLabel>
        </>
      )
    }
  )
)

export default PostalInfoForm
