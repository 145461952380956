import { TransactionHistoryItemCardProps } from '@/components/TransactionHistoryItemCard'
import { DD_MMM_YYYY, MMMM_YYYY } from '@/constants'
import { fundNameMapType } from '@/hooks/useQueryFundNameMapping'
import {
  OrderStatusEnum,
  OrderTypeEnum,
  TransactionHistoryItem,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export enum TransactionTypeEnum {
  CashIn = 'cash-in',
  CashOut = 'cash-out',
  IncomeDistribution = 'income-distribution',
  BonusDistribution = 'bonus-distribution',
  CoolingOff = 'cooling-off',
  SwitchOut = 'switch-out',
  SwitchIn = 'switch-in',
  NA = '',
}

export const UNIT_PRICE_ADJUSTMENT = 'UNIT_PRICE_ADJUSTMENT'
export const PENDING_REFUND = 'PENDING_REFUND'
export const PENDING_PAYMENT = 'PENDING_PAYMENT'
export const REFUND_COMPLETED = 'REFUND_COMPLETED'

export const FAILED_ORDER_STATUS_LIST = [
  OrderStatusEnum.FAILED,
  OrderStatusEnum.PAYOUT_REJECTED,
  OrderStatusEnum.PAYMENT_FAILED,
]

export const TRANSFER_IN_TRANSACTION_TYPES = [
  TransactionTypeEnum.CashIn,
  TransactionTypeEnum.SwitchIn,
]

export const TRANSFER_OUT_TRANSACTION_TYPES = [
  TransactionTypeEnum.CashOut,
  TransactionTypeEnum.CoolingOff,
  TransactionTypeEnum.SwitchOut,
]

export const DISTRIBUTION_TRANSACTION_TYPES = [
  TransactionTypeEnum.IncomeDistribution,
  TransactionTypeEnum.BonusDistribution,
]

export const SWITCHING_TRANSACTION_TYPES = [
  TransactionTypeEnum.SwitchOut,
  TransactionTypeEnum.SwitchIn,
]

export const COMPLETED_ORDER_TYPES = [
  OrderStatusEnum.COMPLETED,
  OrderStatusEnum.WITHDRAWAL_COMPLETED,
]

export const SWITCHING_FINAL_STATUS = [
  OrderStatusEnum.COMPLETED,
  OrderStatusEnum.WITHDRAWAL_COMPLETED,
  OrderStatusEnum.PAYOUT_REJECTED,
  OrderStatusEnum.PAYMENT_FAILED,
]

export const FAILED_FINAL_STATUS = [OrderStatusEnum.FAILED, PENDING_PAYMENT]

export interface TransactionHistoryDetails {
  month: string
  items: {
    dateStr: string
    items: TransactionHistoryItem[]
  }[]
}

export const convertTransactions = (transactions: TransactionHistoryItem[]) => {
  const _transactions: TransactionHistoryDetails[] = []

  transactions.forEach((trnx) => {
    const _date =
      trnx.type === OrderTypeEnum.ManSell ? trnx.navDate : trnx.submittedDate
    const date = dayjs.utc(_date).local()
    const month = date.format(MMMM_YYYY)
    const dateStr = date.format(DD_MMM_YYYY)

    const _currMonth = _transactions.find((mItem) => mItem.month === month)
    if (!_currMonth) {
      //append new month-year to _statements
      _transactions.push({
        month,
        items: [
          {
            dateStr,
            items: [trnx],
          },
        ],
      })
    } else {
      const _currDate = _currMonth.items.find(
        (dItem) => dItem.dateStr === dateStr
      )
      if (!_currDate) {
        //append new date to current month
        _currMonth.items.push({
          dateStr,
          items: [trnx],
        })
      } else {
        //append new item to current date
        _currDate.items.push(trnx)
      }
    }
  })

  return _transactions
}

export const getTransactionDetails = (
  transaction: TransactionHistoryItem,
  fundNamesMapping: fundNameMapType
): TransactionHistoryItemCardProps => {
  const { type, status: trnxStatus, adjustmentCompleted } = transaction // body[x].amount, body[x].type, body[x].status
  const transactionType = getTransactionType(type)

  // update status mapping to use status 5 as a SETTLED transaction for cash out & cooling off
  const status =
    [TransactionTypeEnum.CashOut, TransactionTypeEnum.CoolingOff].includes(
      transactionType
    ) && trnxStatus === OrderStatusEnum.COMPLETED // status = 5
      ? OrderStatusEnum.SETTLED
      : trnxStatus

  // show completedAmount/adjustedAmount in case of cash-out/cash-in completed transaction
  const amount = getTransactionAmount({ transactionType, ...transaction })

  const name = getFundNameByCode(fundNamesMapping, transaction.fundCode)

  const transferredFundName =
    transactionType === TransactionTypeEnum.SwitchOut
      ? getFundNameByCode(fundNamesMapping, transaction.fundCodeTo)
      : getFundNameByCode(fundNamesMapping, transaction.fundCodeFrom)

  /* get submitted status details for all type of transactions */
  const submittedStatus = getSubmittedStatus(transaction)

  /* get processing status details for following transactions:
      - Settled
      - Rejected
      - Completed
      - Failed
   */
  const processingStatus = [
    OrderStatusEnum.SETTLED,
    OrderStatusEnum.REJECTED,
    OrderStatusEnum.COMPLETED,
    OrderStatusEnum.WITHDRAWAL_COMPLETED,
    OrderStatusEnum.FAILED,
    OrderStatusEnum.PAYOUT_REJECTED,
    OrderStatusEnum.PAYMENT_FAILED,
  ].includes(status)
    ? getProcessingStatus(transaction)
    : undefined

  /* get result status details, if transaction is Completed or Failed */
  const resultStatus = [
    OrderStatusEnum.COMPLETED,
    OrderStatusEnum.WITHDRAWAL_COMPLETED,
    OrderStatusEnum.FAILED,
    OrderStatusEnum.PAYOUT_REJECTED,
    OrderStatusEnum.PAYMENT_FAILED,
  ].includes(status)
    ? getResultStatus(transaction, type)
    : undefined

  /* get result unit price adjustment details, if transaction is Completed with unit adjustment and is of type Cash in/out */
  const unitPriceAdjustment =
    ((transactionType === TransactionTypeEnum.CashIn &&
      status === OrderStatusEnum.COMPLETED) ||
      (transactionType === TransactionTypeEnum.CashOut &&
        status === OrderStatusEnum.WITHDRAWAL_COMPLETED)) &&
    !!adjustmentCompleted
      ? getUnitAdjustmentStatus(transaction, transactionType)
      : undefined

  const refundStatus =
    SWITCHING_TRANSACTION_TYPES.includes(transactionType) &&
    transaction.refundCompleted
      ? getRefundStatus(transaction)
      : undefined

  return {
    name,
    type,
    transferredFundName,
    transactionType,
    amount,
    status,
    submittedStatus,
    processingStatus,
    resultStatus,
    unitPriceAdjustment,
    refundStatus,
  }
}

interface GetTransactionAmountProps
  extends Pick<
    TransactionHistoryItem,
    | 'status'
    | 'amount'
    | 'adjustmentCompleted'
    | 'adjustmentAmount'
    | 'completedAmount'
  > {
  transactionType: TransactionTypeEnum
}

const getTransactionAmount = ({
  transactionType,
  amount = 0,
  status,
  adjustmentCompleted,
  completedAmount = 0,
  adjustmentAmount = 0,
}: GetTransactionAmountProps): number => {
  return transactionType === TransactionTypeEnum.CashOut &&
    status == OrderStatusEnum.WITHDRAWAL_COMPLETED
    ? adjustmentCompleted
      ? adjustmentAmount
      : completedAmount
    : transactionType === TransactionTypeEnum.CashIn &&
      status == OrderStatusEnum.COMPLETED &&
      adjustmentCompleted
    ? adjustmentAmount
    : SWITCHING_TRANSACTION_TYPES.includes(transactionType) &&
      SWITCHING_FINAL_STATUS.includes(status)
    ? completedAmount
    : transactionType === TransactionTypeEnum.CoolingOff
    ? completedAmount
    : amount
}

const getSubmittedStatus = (
  transaction: TransactionHistoryItem
): TransactionHistoryItemCardProps['submittedStatus'] => {
  return {
    ...transaction,
    date: transaction.submittedDate ?? undefined, //5 body[x].submittedDate
  }
}

const getProcessingStatus = (
  transaction: TransactionHistoryItem
): TransactionHistoryItemCardProps['processingStatus'] => {
  console.log(transaction)
  return {
    date: transaction.processingDate ?? undefined, //6 body[x].processingDate
  }
}

const getResultStatus = (
  transaction: TransactionHistoryItem,
  type: OrderTypeEnum
): TransactionHistoryItemCardProps['resultStatus'] => {
  return {
    ...transaction,
    isFullCashOut:
      transaction.type === OrderTypeEnum.FullSell &&
      transaction.status === OrderStatusEnum.WITHDRAWAL_COMPLETED,
    date:
      (type === OrderTypeEnum.ManSell
        ? transaction.navDate
        : transaction.completedDate) ?? undefined, //14 body[x].completedDate
  }
}

const getUnitAdjustmentStatus = (
  transaction: TransactionHistoryItem,
  transactionType: TransactionTypeEnum.CashIn | TransactionTypeEnum.CashOut
): TransactionHistoryItemCardProps['unitPriceAdjustment'] => {
  return {
    ...transaction,
    date: transaction.adjustmentDate ?? undefined, //18 body[x].adjustmentDate
    // show completedAmount as requested amount for cash out transaction
    amount:
      transactionType === TransactionTypeEnum.CashOut
        ? transaction.completedAmount
        : transaction.amount,
  }
}

const getRefundStatus = (
  transaction: TransactionHistoryItem
): TransactionHistoryItemCardProps['refundStatus'] => {
  return {
    ...transaction,
    date: transaction.submittedDate ?? undefined, // body[x].refundDate
  }
}

const getFundNameByCode = (
  fundNamesMapping: fundNameMapType,
  fundCode?: string
) => {
  return !!fundCode &&
    Object.prototype.hasOwnProperty.call(fundNamesMapping, fundCode)
    ? fundNamesMapping[fundCode]
    : ''
}

export const getTransactionType = (type: OrderTypeEnum) => {
  switch (type) {
    case OrderTypeEnum.Buy:
    case OrderTypeEnum.ManBuy:
      return TransactionTypeEnum.CashIn
    case OrderTypeEnum.SwitchOut:
    case OrderTypeEnum.ManSwitchOut:
    case OrderTypeEnum.SwitchAll:
      return TransactionTypeEnum.SwitchOut
    case OrderTypeEnum.SwitchIn:
    case OrderTypeEnum.ManSwitchIn:
      return TransactionTypeEnum.SwitchIn
    case OrderTypeEnum.Sell:
    case OrderTypeEnum.ManSell:
    case OrderTypeEnum.FullSell:
      return TransactionTypeEnum.CashOut
    case OrderTypeEnum.CoolOff:
      return TransactionTypeEnum.CoolingOff
    case OrderTypeEnum.Bonus:
      return TransactionTypeEnum.BonusDistribution
    case OrderTypeEnum.Dividend:
      return TransactionTypeEnum.IncomeDistribution

    default:
      return TransactionTypeEnum.NA
  }
}

export const getOrderStatusForTransactionType = (type: OrderTypeEnum) => {
  switch (type) {
    case OrderTypeEnum.Buy:
    case OrderTypeEnum.ManBuy:
      return [
        OrderStatusEnum.SETTLED,
        OrderStatusEnum.COMPLETED,
        OrderStatusEnum.REJECTED,
        OrderStatusEnum.PAYMENT_FAILED,
      ]
    case OrderTypeEnum.Sell:
    case OrderTypeEnum.ManSell:
    case OrderTypeEnum.FullSell:
      return [
        OrderStatusEnum.SETTLED,
        OrderStatusEnum.COMPLETED,
        OrderStatusEnum.PAYMENT_FAILED,
        OrderStatusEnum.PAYOUT_REJECTED,
        OrderStatusEnum.WITHDRAWAL_COMPLETED,
      ]
    case OrderTypeEnum.Bonus:
    case OrderTypeEnum.Dividend:
      return [OrderStatusEnum.COMPLETED]

    case OrderTypeEnum.CoolOff:
      return [
        OrderStatusEnum.COMPLETED,
        OrderStatusEnum.PAYOUT_REJECTED,
        OrderStatusEnum.WITHDRAWAL_COMPLETED,
      ]

    case OrderTypeEnum.SwitchOut:
    case OrderTypeEnum.ManSwitchOut:
    case OrderTypeEnum.SwitchAll:
    case OrderTypeEnum.SwitchIn:
    case OrderTypeEnum.ManSwitchIn:
      return [
        OrderStatusEnum.PENDING_PAYMENT,
        OrderStatusEnum.SETTLED,
        OrderStatusEnum.COMPLETED,
        OrderStatusEnum.WITHDRAWAL_COMPLETED,
        OrderStatusEnum.PAYMENT_FAILED,
        OrderStatusEnum.PAYOUT_REJECTED,
      ]

    default:
      return []
  }
}

export const TimeLineStatusMapping = {
  [OrderStatusEnum.SETTLED]: 'status-PROCESSING',
  [OrderStatusEnum.PENDING_PAYMENT]: 'status-SUBMITTED',
  [OrderStatusEnum.COMPLETED]: 'status-COMPLETED',
  [OrderStatusEnum.REJECTED]: 'status-PROCESSING',
  [OrderStatusEnum.SWITCHED]: 'status-SWITCHED',
  [OrderStatusEnum.PAYMENT_FAILED]: 'status-FAILED',
  [OrderStatusEnum.WITHDRAWAL_COMPLETED]: 'status-COMPLETED',
  [OrderStatusEnum.PAYOUT_REJECTED]: 'status-FAILED',
  [OrderStatusEnum.FAILED]: 'status-FAILED',
  [UNIT_PRICE_ADJUSTMENT]: 'status-UNIT_PRICE_ADJUSTMENT',
  [PENDING_REFUND]: 'status-PENDING_REFUND',
  [PENDING_PAYMENT]: 'status-PENDING_PAYMENT',
  [REFUND_COMPLETED]: 'status-REFUND_COMPLETED',
}
