import {
  CustomerAMLStatusEnum as AmlStatusEnum,
  FormDetailPayload,
  LanguageCodeEnum as LangEnum,
  OrderFundResDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { CS_URL, DEF_DECIMAL } from '@pimy-b2cweb/frontend-lib'
import { AuthedStatusEnum } from '@/config'
import { NO_REFERRER } from '@/constants'
import {
  ERR_NETWORK,
  INTERNAL_SERVER_ERROR,
  INVALID_REQUEST,
  INVALID_SESSION,
  UNKNOWN_ERROR,
} from '@/constants/errorCodes'
import { SelectedFund } from '@/hooks/useInvestmentReducer'
import { LovItem } from '@/stores/lov'
import { parsePhoneNumber } from 'awesome-phonenumber'
// import { INVEST_SESSION } from '@/constants/sessionKeys'

export const isNumber = (val: string) => {
  return /^\d+$/.test(val)
}

export const isNumberOrLetter = (val: string) => {
  return /^[a-zA-Z0-9]+$/.test(val)
}

export const delay = (sec = 3000) => {
  return new Promise((resolve) => {
    setTimeout(resolve, sec)
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getErrorResponseCode = (mutatedError: any) => {
  if (!!mutatedError?.response?.data?.code) {
    return mutatedError.response.data.code
  }
  if (!!mutatedError?.response?.status) {
    // switch (mutatedError.response.status) {
    //   case 400:
    //   case 401:
    //     return INVALID_REQUEST
    //   case 403:
    //     return INVALID_SESSION
    //   case 500:
    //   case 501:
    //   case 502:
    //     return INTERNAL_SERVER_ERROR
    //   default:
    //     return UNKNOWN_ERROR
    // }
    const statusCode = mutatedError.response.status
    if (statusCode === 403) return INVALID_REQUEST
    if (statusCode >= 400 && statusCode <= 499) return INVALID_SESSION
    if (statusCode >= 500 && statusCode <= 599) return INTERNAL_SERVER_ERROR
    return UNKNOWN_ERROR
  }
  if (mutatedError.code === ERR_NETWORK) return INTERNAL_SERVER_ERROR
  if (!!mutatedError?.message) {
    return mutatedError.message
  }
  return UNKNOWN_ERROR
}

export const getSessionStorage = (key: string) => {
  if (typeof window === 'undefined') return
  const data = sessionStorage.getItem(key)
  if (!data) return

  const jsonData = JSON.parse(data)

  return jsonData
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setSessionStorage = (key: string, value: any) => {
  if (!key) return

  const jsonString = JSON.stringify(value)

  sessionStorage.setItem(key, jsonString)
}

export const removeSessionStorage = (key: string) => {
  sessionStorage.removeItem(key)
}

export const apiLangCode = (lang: string): LangEnum => {
  const _lang = lang.toLowerCase().split('-')[0]
  return _lang === LangEnum.ms ? LangEnum.ms : LangEnum.en
}

export const getSignedNumber = ({
  num,
  decimal = 2,
  style = 'decimal',
}: {
  num: number
  decimal?: number
  style?: Intl.NumberFormatPartTypes
}) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    style: style,
    signDisplay: 'exceptZero',
  }).format(num)
}

export const getLocalFormattedNumber = ({
  num,
  locale = 'en-US',
  decimal = 2,
  style = 'decimal',
}: {
  num: number
  locale?: string
  decimal?: number
  style?: Intl.NumberFormatPartTypes
}) => {
  return new Intl.NumberFormat(locale, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    style: style,
  }).format(num)
}

export const getSignedCurrency = ({
  num,
  decimal = 2,
  currency = 'MYR',
  currencyDisplay = 'symbol',
}: {
  num: number
  decimal?: number
  currency?: string
  currencyDisplay?: 'code' | 'symbol' | 'narrowSymbol' | 'name'
}) => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
    style: 'currency',
    signDisplay: 'exceptZero',
    currency: currency,
    currencyDisplay: currencyDisplay,
  }).format(num)
}

export const formatAddress = ({
  address,
  idPrefix = 'addressLine',
  addressLineNum,
}: {
  address: unknown
  idPrefix?: string
  addressLineNum: number
}) => {
  if (typeof address !== 'object' || address == null) return address

  const _num = addressLineNum <= 1 ? 1 : addressLineNum
  const fieldNames = Array.from({ length: _num }, (_, i) => i + 1).map(
    (keyNum) => `${idPrefix}${keyNum}`
  )

  const _addressArray = fieldNames.reduce(
    (accu, fieldName) =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      !!address[fieldName] ? [...accu, address[fieldName]] : accu,
    []
  )

  return {
    ...address,
    ...fieldNames.reduce(
      (accu, fieldName, idx) => ({
        ...accu,
        [fieldName]: _addressArray[idx] || undefined,
      }),
      {}
    ),
  }
}

export const validateExternalURLs = (URL: string) => {
  const allowdedURLs = [
    process.env.REACT_APP_EPF_PORTAL,
    process.env.PUBLIC_URL,
  ]

  const isValidURL = allowdedURLs.some((allowdedURL) =>
    URL.startsWith(allowdedURL as string)
  )

  return isValidURL ? URL : ''
}

export enum verifyAMLStatusEnum {
  EcddPending = AuthedStatusEnum.EcddPending,
  EcddSubmitted = AuthedStatusEnum.EcddSubmitted,
  EcddRejected = AuthedStatusEnum.EcddRejected,
  EcddExpired = AuthedStatusEnum.EcddExpired,
  AmlPassed = 'amlPassed',
  AmlUnknown = 'amlUnknown',
}
export const verifyAMLStatus = (
  amlStatus: AmlStatusEnum
): verifyAMLStatusEnum => {
  switch (amlStatus) {
    case AmlStatusEnum.ecdd_pending:
      return verifyAMLStatusEnum.EcddPending

    case AmlStatusEnum.ecdd_submitted:
      return verifyAMLStatusEnum.EcddSubmitted

    case AmlStatusEnum.ecdd_rejected:
      return verifyAMLStatusEnum.EcddRejected

    case AmlStatusEnum.ecdd_expired:
      return verifyAMLStatusEnum.EcddExpired

    case AmlStatusEnum.passed:
      return verifyAMLStatusEnum.AmlPassed

    case AmlStatusEnum.unknown:
    default:
      return verifyAMLStatusEnum.AmlUnknown
  }
}

export const checkIfCustomerIsIndividual = (occupationCode: string) => {
  /* 
    Following are Individual Customer Occupations:
      {code: "23", name: "STUDENT"}
      {code: "62", name: "Unemployed"}
      {code: "65", name: "Freelancer"}
      {code: "90", name: "Retiree"}
      {code: "91", name: "Housewife"} 
  */
  const individualOccupationCode = ['23', '62', '65', '90', '91']

  return individualOccupationCode.includes(occupationCode)
}

export const checkForNonBusinessCustomer = (occupationCode: string) => {
  /* 
    Following are Non-Business Occupations:
      {code: "23", name: "STUDENT"}
      {code: "62", name: "Unemployed"}
      {code: "90", name: "Retiree"}
      {code: "91", name: "Housewife"} 
  */
  const nonBusinessOccupationCode = ['23', '62', '90', '91']

  return nonBusinessOccupationCode.includes(occupationCode)
}

export const scrollTop = () => window.scrollTo({ top: 0 })

export const openCSLink = () => window.open(CS_URL, '_blank', NO_REFERRER)

export const postHiddenForm = (
  action: string,
  formFileds: FormDetailPayload[]
) => {
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = action

  formFileds.forEach(({ name, value }) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = name
    input.value = value
    form.appendChild(input)
  })

  document.body.appendChild(form)
  form.submit()
}

export const resetLocationState = () =>
  window.history.replaceState({}, '', window.location.pathname)

export const calTotalAmount = (funds: Array<Pick<SelectedFund, 'amount'>>) =>
  funds.reduce((acc, { amount }) => acc + amount, 0)

export const filterByLov = (val: string, lov: LovItem[]) => {
  return lov.find(({ value }) => value === val)
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getKeyByValue = (object: any, value: any) =>
  Object.keys(object).find((key) => object[key] === value)

/* 
Function to format phone number
Input: Phone number with country code. e.g. 60123456789
Output: Phone number without cuntrycode. e.g. 123456789
 */
export const formatMobileNo = (mobileNo: string) => {
  let parsedNumber = mobileNo
  if (mobileNo[0] != '+') parsedNumber = `+${mobileNo}`
  let parsed = parsePhoneNumber(parsedNumber)
  if (!parsed.valid) parsed = parsePhoneNumber(mobileNo, { regionCode: 'MY' })
  if (!parsed.valid) return mobileNo

  return (parsedNumber = parsed.number.significant)
}

/* Function to get fund codes in comma separated string format
    e.g. FunCode1,FundCode2
 */
export const getFundCodesString = (funds: SelectedFund[]) =>
  funds.map(({ code }) => code).join(',')

export const checkEpfTokenExpiration = (epfExpAt: number) =>
  new Date(epfExpAt * 1000).getTime() >= new Date().getTime()

export const insufficientBalanceCheck = ({
  availableAmount,
  amount,
  minAmount,
}: Pick<OrderFundResDto, 'availableAmount' | 'amount' | 'minAmount'>) => {
  const _availableAmount = Number(availableAmount.toFixed(DEF_DECIMAL))
  const _amount = Number(amount.toFixed(DEF_DECIMAL))
  const _minAmount = Number(minAmount.toFixed(DEF_DECIMAL))
  return _availableAmount - _amount < _minAmount && _amount !== _availableAmount
}
