import { useDispatch } from 'react-redux'
import { AuthedStatusEnum } from '@/config'
import { EPF_TOKEN } from '@/constants/sessionKeys'
import { sessionAuthedStatus } from '@/stores/auth'
import { sessionReset as sessionResetEpfToken } from '@/stores/epfToken'
import { removeSessionStorage } from '@/utils'

export const useClearEpfStatus = () => {
  const dispatch = useDispatch()

  return () => {
    removeSessionStorage(EPF_TOKEN)
    dispatch(sessionResetEpfToken())
    dispatch(sessionAuthedStatus({ status: AuthedStatusEnum.LoggedIn }))
  }
}
export default useClearEpfStatus
