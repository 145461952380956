import { useEffect, useState } from 'react'
import { useMutationGetEkycToken } from '@/api/onboardingApi'
import { getSessionStorage, postHiddenForm } from '@/utils'

export const useGetEkycToken = () => {
  const {
    mutate,
    status: mutateStatus,
    data: mutatedData,
    error: mutatedError,
    isLoading: mutateIsLoading,
  } = useMutationGetEkycToken()
  const [stateIsLoading, setStateIsLoading] = useState(false)

  //TODO: backdoor for sit mock ekyc- to be removed
  const useMockEkyc = getSessionStorage('useMockEkyc') === 'yes'

  const handleMutate = () => {
    setStateIsLoading(true)
    mutate()
  }

  useEffect(() => {
    //if api call succeed, submit post to ekyc site --
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log('mutatedData: ', mutatedData)
      const { formUrl, formDetail } = mutatedData

      // const action = formUrl
      //TODO: backdoor for sit mock ekyc- to be removed
      const action = useMockEkyc
        ? 'https://xc3pc5l9y0.execute-api.ap-southeast-1.amazonaws.com/prod/'
        : formUrl

      postHiddenForm(action, formDetail)
    }
  }, [mutatedData, mutateStatus])

  return {
    handleMutate,
    mutateStatus,
    mutatedData,
    mutatedError,
    isLoading: stateIsLoading || mutateIsLoading,
  }
}

export default useGetEkycToken
