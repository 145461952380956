import useInitInvestmentData from '@/hooks/useInitInvestmentData'
// import { useQueryRiskLevelList } from '@/api/riskProfileApi'
import { convertPortfolioFund } from '@/utils/portfolioFund'
import { useMemo } from 'react'

export const useInitData = () => {
  // const { data: metaDataResponse } = useQueryCustomerMetadata({})
  const { portfolioData, fundList, isLoading, isError } =
    useInitInvestmentData()
  // const { data: riskLevelListResponse } = useQueryRiskLevelList()

  const epfFunds = useMemo(() => {
    if (!portfolioData?.epfGroup.funds || !fundList) return []
    const epfFunds = portfolioData.epfGroup.funds.slice(0, 4)
    return convertPortfolioFund({
      pFunds: epfFunds,
      fundList: fundList,
    })
  }, [portfolioData?.epfGroup.funds, fundList])

  const cashFunds = useMemo(() => {
    if (!portfolioData?.cashGroup.funds || !fundList) return []
    const cashFunds = portfolioData.cashGroup.funds.slice(0, 4)
    return convertPortfolioFund({
      pFunds: cashFunds,
      fundList: fundList,
    })
  }, [portfolioData?.cashGroup.funds, fundList])

  const allFunds = useMemo(
    () => [...epfFunds, ...cashFunds],
    [epfFunds, cashFunds]
  )

  return {
    // metaDataResponse,
    portfolioData,
    fundList,
    // riskLevelListResponse,
    epfFunds,
    cashFunds,
    allFunds,
    isLoading,
    isError,
  }
}

export default useInitData
