import { jwtDecode } from 'jwt-decode'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import {
  EPF_TOKEN,
  EKYC_REF_NO,
  // TRX_REF_NO
} from '@/constants/sessionKeys'
import { EpfTokenSessionState, sessionSetToken } from '@/stores/epfToken'
import {
  getSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from '@/utils'

export const InitSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const decodeAndManageToken = useDecodeAndManageToken()

  useEffect(() => {
    // read epf from query string and write to sessionStorage --
    const queryEpfToken = searchParams.get(EPF_TOKEN)

    if (!!queryEpfToken) {
      decodeAndManageToken(queryEpfToken)

      setSessionStorage(EPF_TOKEN, queryEpfToken)

      searchParams.delete(EPF_TOKEN)
    } else {
      // read epf from sessionStorage and export --
      decodeAndManageToken(getSessionStorage(EPF_TOKEN))
    }

    const searchEkycRefNo = searchParams.get(EKYC_REF_NO)
    if (!!searchEkycRefNo) {
      setSessionStorage(EKYC_REF_NO, searchEkycRefNo)
      searchParams.delete(EKYC_REF_NO)
    }

    if (!!queryEpfToken || !!searchEkycRefNo) {
      setSearchParams(searchParams)
    }

    //TODO: backdoor for sit mock ekyc- to be removed
    console.log(
      'allow use mock ekyc',
      process.env.REACT_APP_ALLOW_MOCK_EKYC,
      process.env.REACT_APP_ALLOW_MOCK_EKYC === 'yes'
    )
    if (
      process.env.REACT_APP_ALLOW_MOCK_EKYC === 'yes' &&
      searchParams.get('useMockEkyc') === 'yes'
    ) {
      setSessionStorage('useMockEkyc', 'yes')
    } else {
      removeSessionStorage('useMockEkyc')
    }
    //[end]TODO: backdoor for sit test - to be removed

    // const trxRefNo = searchParams.get(TRX_REF_NO)
    // if (!!trxRefNo) {
    //   setSessionStorage(TRX_REF_NO, trxRefNo)
    //   searchParams.delete(TRX_REF_NO)
    // }
  }, [])

  return null
}

const useDecodeAndManageToken = () => {
  const dispatch = useDispatch()

  return (token?: string) => {
    if (!token) return

    const decoded: EpfTokenSessionState['decoded'] = jwtDecode(token)
    if (!decoded?.exp) return

    const expAt = Number(decoded.exp) * 1000 // expAt time in ms

    const expAtDt = new Date(expAt)
    if (expAtDt < new Date()) {
      //if epfToken expired, delete sessionStorage
      removeSessionStorage(EPF_TOKEN)
      return
    }

    dispatch(
      sessionSetToken({
        token,
        decoded,
        expAt,
      })
    )
  }
}

export default InitSearchParams
