import { ActionModal, ActionModalProps } from '@pimy-b2cweb/frontend-lib'
import { useTranslation, Trans } from 'react-i18next'
import { CSLink } from '@pimy-b2cweb/frontend-lib'

export const ActivationEmailModal = ({
  modalClose,
  ...rest
}: Omit<
  ActionModalProps,
  'title' | 'actions' | 'actionsStyle' | 'closeBtn' | 'onClose' | 'children'
> & {
  modalClose: () => void
}) => {
  const { t } = useTranslation(['activationEmailModal', 'common'])

  return (
    <ActionModal
      {...rest}
      title={t('activate-your-account')}
      actions={[
        {
          label: t('OK', { ns: 'common' }),
          onClick: modalClose,
          variant: 'contained',
        },
      ]}
      actionsStyle='full'
      closeBtn={true}
      onClose={modalClose}
    >
      <Trans
        i18nKey={'activation-email-sent-please-check'}
        t={t}
        components={{ P: <p />, CS: <CSLink /> }}
      />
    </ActionModal>
  )
}

export default ActivationEmailModal
