import { useMutationVerifyOtp } from '@/api/commonApi'
import { useMutationConfirmSwitchingOrderInstruction } from '@/api/orderApi'
import { VerifyOtpFormProps, VerifyOtpUi } from '@/components/VerifyOtpUi'
import { useResendOtp } from '@/hooks/useResendOtp'
import { sessionResetVerifyOtp } from '@/stores/auth'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { getErrorResponseCode } from '@/utils'
import { OrderCreateResultResDto } from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useResponseError } from '@pimy-b2cweb/frontend-lib'
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export interface VerifyOtpProps {
  goNext: () => void
  setTrxDetails: (trxDetails: OrderCreateResultResDto) => void
  openTechnicalErrorModal: () => void
}
export const VerifyOtp = ({
  goNext,
  setTrxDetails,
  openTechnicalErrorModal,
}: VerifyOtpProps) => {
  const dispatch = useDispatch()
  const [responseErrorAttrs, setResponseErrorAttrs] = useResponseError()

  const { notificationRecipient, canResend } = useSelector(
    selectAuthSessionState
  )

  const {
    mutate: mutateVO,
    isLoading: mutateIsLoadingVO,
    status: mutateStatusVO,
    data: mutatedDataVO,
    error: mutatedErrorVO,
  } = useMutationVerifyOtp()

  const {
    mutate: mutateSO,
    isLoading: mutateIsLoadingSO,
    status: mutateStatusSO,
    data: mutatedDataSO,
    error: mutatedErrorSO,
  } = useMutationConfirmSwitchingOrderInstruction()

  const { onResetResendError, ...useResendOtpRest } = useResendOtp({
    initCanResend: canResend !== false,
    handleResetPageError: () => setResponseErrorAttrs(undefined),
  })

  const onSubmit = async (data: VerifyOtpFormProps) => {
    console.log(data)
    setResponseErrorAttrs(undefined)
    onResetResendError()
    mutateVO({
      ...data,
    })
  }

  useEffect(() => {
    if (mutateStatusVO === 'error') {
      const errMsg = getErrorResponseCode(mutatedErrorVO)
      setResponseErrorAttrs({ i18nKey: errMsg })
      return
    }

    setResponseErrorAttrs(undefined)

    if (mutateStatusVO === 'success' && !!mutatedDataVO) {
      console.log('mutatedDataVO: ', mutatedDataVO)
      mutateSO({ requestToken: mutatedDataVO.sessionToken })
    }
  }, [mutateStatusVO, mutatedDataVO, mutatedErrorVO])

  useEffect(() => {
    if (mutateStatusSO === 'error') {
      console.log('mutatedErrorSO: ', mutatedErrorSO)
      openTechnicalErrorModal()
      return
    }

    setResponseErrorAttrs(undefined)

    if (mutateStatusSO === 'success' && !!mutatedDataSO) {
      console.log('mutatedDataSO: ', mutatedDataSO)
      dispatch(sessionResetVerifyOtp())
      setTrxDetails({ ...mutatedDataSO })
      goNext()
    }
  }, [mutateStatusSO, mutatedDataSO, mutatedErrorSO])

  const isLoading = useMemo(
    () => mutateIsLoadingVO || mutateIsLoadingSO,
    [mutateIsLoadingVO, mutateIsLoadingSO]
  )

  return (
    <VerifyOtpUi
      notificationRecipient={notificationRecipient}
      responseErrorAttrs={responseErrorAttrs}
      onSubmit={onSubmit}
      isLoading={isLoading}
      canResend={canResend}
      {...useResendOtpRest}
      operation='continue'
    />
  )
}

export default VerifyOtp
