import { RoutePathEnum } from '@/constants/routePath'
import { selectEpfToken } from '@/stores/epfToken.selectors'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import Loading from '@/components/Loading'
import HelmetSiteTitle from '@/components/HelmetSiteTitle'
import { useTranslation } from 'react-i18next'
import SwitchAmount, { SwitchingFormProps } from './subpages/SwitchAmount'
import SwitchSummary from './subpages/SwitchSummary'
import VerifyOtp from './subpages/VerifyOTP'
import SwitchResult from './subpages/SwitchResult'
import ErrorNotFound from '../ErrorNotFound'
import PostLogonMainBody from '@/layout/PostLogonMainBody'
import PostLogonContainer from '@/layout/PostLogonContainer'
import {
  ResponseErrorProps,
  useModal,
  useResponseError,
} from '@pimy-b2cweb/frontend-lib'
import {
  AccountStatusEnum,
  OrderCreateResultResDto,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { SwitchActionEnum } from '../Switching'
import {
  SwitchingFunds,
  useSwitchingDataReducer,
} from '@/hooks/useSwitchingDataReducer'
import { useInitSwitchingData } from '@/hooks/useInitSwitchingData'
import { useMutationQuerySwitchingOrderInstructionData } from '@/api/orderApi'
import SubaccountInvalidModal, {
  ActionTypeEnum,
} from '@/components/SubaccountInvalidModal'
import useHandleUserStatus from '@/hooks/useHandleUserStatus'
import TechnicalErrorModal from '@/components/TechnicalErrorModal'

export enum SwitchingFormPageState {
  SWITCHING_FORM = 'switching_form',
  SWITCHING_SUMMARY = 'switching_summary',
  VERIFY_OTP = 'verify_otp',
  SWITCHING_RESULT = 'switching_result',
}

export interface SwitchingTransactionDetails extends OrderCreateResultResDto {}

const SwitchingForm = () => {
  const { t } = useTranslation('switchingPage')
  const { state } = useLocation()
  console.log(state)
  const {
    productId,
    subaccountNo,
    funds = [],
    agentCode,
    subaccountStatus,
  } = state || {}
  const navigate = useNavigate()
  const xEpfToken = useSelector(selectEpfToken) || undefined
  const [pageState, setPageState] = useState(
    SwitchingFormPageState.SWITCHING_FORM
  )
  const [transactionDetails, setTransactionDetails] =
    useState<SwitchingTransactionDetails>()

  const [orderFormError, setOrderFormError] = useResponseError()

  const { isValidUserStatus, handleCheckUserStatus } = useHandleUserStatus()

  const subaccountInvalidModal = useModal()
  const technicalErrorModal = useModal()

  if (!xEpfToken && (!subaccountNo || !funds?.length || !productId)) {
    navigate(RoutePathEnum.DASHBOARD)
  }

  const {
    mutate,
    status: mutateStatus,
    isLoading: mutateIsLoading,
    isError: mutateIsError,
    data: mutatedData,
  } = useMutationQuerySwitchingOrderInstructionData()

  useEffect(() => {
    console.log('xEpfToken : ', xEpfToken)
    if (!!xEpfToken) {
      mutate({ funds: [] })
    }
  }, [])

  const {
    switchingData,
    setSwitchingOrder,
    setSwitchingData,
    setSwitchingFunds,
  } = useSwitchingDataReducer({
    initData: {
      productId,
      subaccountNo,
      funds,
      changeIndex: 0,
      agentCode,
      subaccountStatus,
    },
  })

  const { isLoading: isLoadingSD, isError: isErrorSD } = useInitSwitchingData()

  const isLoading = useMemo(
    () => isLoadingSD || mutateIsLoading,
    [isLoadingSD, mutateIsLoading]
  )

  const isError = useMemo(
    () => isErrorSD || mutateIsError,
    [isErrorSD, mutateIsError]
  )

  useEffect(() => {
    if (!!isError) {
      navigate(RoutePathEnum.ERROR)
    }
  }, [isError])

  useEffect(() => {
    if (mutateStatus === 'success' && !!mutatedData) {
      console.log(mutatedData)
      /* ISAF/ECDD Check */
      handleCheckUserStatus({})
      if (!isValidUserStatus) return

      const switchingFunds: SwitchingFunds[] = mutatedData.funds.map((fund) => {
        return {
          from: {
            ...fund,
          },
          to: {
            fundCode: '',
            amount: fund.amount,
            availableAmount: 0,
            maxAmount: 0,
            minAmount: 0,
          },
        }
      })
      console.log('switchingFunds: ', switchingFunds)
      setSwitchingData({
        ...mutatedData,
        agentCode: !mutatedData.agentCodeDisabled
          ? mutatedData.lastAgent
          : undefined,
        funds: switchingFunds,
      })
    }
  }, [mutateStatus, mutatedData])

  const isSubaccountInvalid = useMemo(
    () => switchingData.subaccountStatus !== AccountStatusEnum.ACTIVE,
    [switchingData]
  )

  const showAgentCode = useMemo(() => {
    if (mutateStatus === 'success' && !!mutatedData) {
      return !mutatedData.agentCodeDisabled
    }
    return true
  }, [mutateStatus, mutatedData])

  const onSubmitOrder = (switchingOrder: SwitchingFormProps) => {
    /* ISAF/ECDD Check */
    handleCheckUserStatus({})
    if (!isValidUserStatus) return

    /* Subaccount invalid check */
    if (isSubaccountInvalid) {
      subaccountInvalidModal.modalOpen()
      return
    }
    setSwitchingOrder({
      ...switchingOrder,
      funds: switchingOrder.orders,
    })
    setPageState(SwitchingFormPageState.SWITCHING_SUMMARY)
  }

  const onClickSwitchOut = (changeIndex: number) => {
    navigate(RoutePathEnum.ORDER_SWITCHING, {
      state: {
        ...switchingData,
        changeIndex,
        action: SwitchActionEnum.SWITCH_OUT,
      },
    })
  }

  const onSwitchIn = (changeIndex: number, orderState: SwitchingFunds[]) => {
    setSwitchingFunds({ funds: orderState })
    navigate(RoutePathEnum.ORDER_SWITCHING, {
      state: {
        ...switchingData,
        funds: orderState,
        changeIndex,
        action: SwitchActionEnum.SWITCH_IN,
      },
    })
  }

  const openTechnicalErrorModal = () => technicalErrorModal.modalOpen()

  return (
    <>
      <HelmetSiteTitle pageTitle={t('switching-form')} />
      {isLoading ? (
        <Loading isLoadingPage />
      ) : Object.values(SwitchingFormPageState).includes(pageState) ? (
        <PostLogonMainBody
          useTransBg={
            ![
              SwitchingFormPageState.SWITCHING_SUMMARY,
              SwitchingFormPageState.SWITCHING_RESULT,
              SwitchingFormPageState.SWITCHING_FORM,
            ].includes(pageState)
          }
        >
          <PostLogonContainer className='!bg-transparent sm:!bg-white'>
            {pageState === SwitchingFormPageState.SWITCHING_FORM ? (
              <SwitchAmount
                {...switchingData}
                showAgentCode={showAgentCode}
                orderFormError={orderFormError}
                onSubmitOrder={onSubmitOrder}
                onSwitchIn={onSwitchIn}
                onClickSwitchOut={onClickSwitchOut}
              />
            ) : pageState === SwitchingFormPageState.SWITCHING_SUMMARY ? (
              <SwitchSummary
                {...switchingData}
                goNext={() => setPageState(SwitchingFormPageState.VERIFY_OTP)}
                goToOrderForm={() =>
                  setPageState(SwitchingFormPageState.SWITCHING_FORM)
                }
                setOrderFormErrMsg={(err: ResponseErrorProps) =>
                  setOrderFormError(err)
                }
                openTechnicalErrorModal={openTechnicalErrorModal}
              />
            ) : pageState === SwitchingFormPageState.VERIFY_OTP ? (
              <VerifyOtp
                goNext={() =>
                  setPageState(SwitchingFormPageState.SWITCHING_RESULT)
                }
                setTrxDetails={(trxDetails: OrderCreateResultResDto) =>
                  setTransactionDetails(trxDetails)
                }
                openTechnicalErrorModal={openTechnicalErrorModal}
              />
            ) : (
              <SwitchResult transactionDetails={transactionDetails} />
            )}
          </PostLogonContainer>
        </PostLogonMainBody>
      ) : (
        <ErrorNotFound />
      )}
      <SubaccountInvalidModal
        {...subaccountInvalidModal}
        action={ActionTypeEnum.SWITCH}
      />
      <TechnicalErrorModal
        {...technicalErrorModal}
        onContinue={() => setPageState(SwitchingFormPageState.SWITCHING_FORM)}
      />
    </>
  )
}

export default SwitchingForm
