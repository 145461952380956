import { useModal } from '@pimy-b2cweb/frontend-lib'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { EcddStatusEnum } from '@/enums/ecddEnums'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { sessionResetUserStatusModal } from '@/stores/userStatusModal'
import { selectUserStatusModalState } from '@/stores/userStatusModal.selectors'
import useEcddModal from '../useEcddModal'

export const useUserStatusModals = () => {
  const dispatch = useDispatch()

  const { isafStatus, ecddStatus } = useSelector(selectAuthSessionState)

  const { isIsafModalOpen, isEcddModalOpen } = useSelector(
    selectUserStatusModalState
  )

  const isafModal = useModal()
  const ecddModal = useEcddModal(ecddStatus || EcddStatusEnum.Passed)

  useEffect(() => {
    if (isIsafModalOpen) {
      isafModal.modalOpen()
      return
    }

    if (isEcddModalOpen) {
      ecddModal.modalOpen()
      return
    }
  }, [isIsafModalOpen, isEcddModalOpen])

  const resetUserStatus = () => {
    dispatch(sessionResetUserStatusModal())
  }

  return {
    isafStatus,
    ecddStatus,
    isafModal: {
      ...isafModal,
      modalClose: () => {
        isafModal.modalClose()
        resetUserStatus()
      },
    },
    ecddModal: {
      ...ecddModal,
      modalClose: () => {
        ecddModal.modalClose()
        resetUserStatus()
      },
    },
  }
}

export default useUserStatusModals
