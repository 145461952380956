import {
  CustomerApi,
  CustomerEcddSubmitReqDto,
  CustomerIndicatorResPayload,
  CustomerMetadataResDto,
  CustomerOnboardSubmitReqDto,
  CustomerProfileEditContactInfoReqDto,
  CustomerProfileEditEmploymentInfoReqDto,
  CustomerProfileEditPersonalInfoReqDto,
  CustomerProfileResDto,
  PortfolioSummaryResDto,
  OtpVerifiedReqDto,
  CustomerApiGetCustomerIndicatorRequestParameters,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  CUSTOMER_INDICATOR,
  CUSTOMER_PROFILE_SETUP,
  CUSTOMER_PROFILE_FETCH,
  SUBMIT_ECDD_FORM,
  CUSTOMER_METADATA_FETCH,
  CUSTOMER_PORTFOLIO_FETCH,
  EDIT_CONTACT_INFO,
  EDIT_EMPLOYMENT_INFO,
  EDIT_PERSONAL_INFO,
  CONFIRM_EDIT_PROFILE_INFO,
} from '@/constants/apiKeys'
import { useSelector } from 'react-redux'
import { selectEpfToken } from '@/stores/epfToken.selectors'
import { selectEkycRefNo } from '@/stores/ekyc.selectors'
import { BaseApiConfig, apiClient } from './api-config'

export const customerApiClient = new CustomerApi(BaseApiConfig, apiClient)

export interface UseMutationCustomerIndicatorRes
  extends CustomerIndicatorResPayload {}

export interface UseMutationCustomerIndicator
  extends CustomerApiGetCustomerIndicatorRequestParameters {
  //TODO: to be removed
  mockCIStatus?:
    | 'goEcddRequired'
    | 'goEcddSubmitted'
    | 'goEcddRejected'
    | 'goEcddExpired'
    | 'goAmlUnknown'
    | 'goNormal'
    | 'goProfile'
    | 'goProfileDeactivate'
    | 'goEkyc'
    | 'goIsafExpired'
    | 'goIsafNotPassed'
    | 'goIsafUnknown'
    | 'goNetworkError'
  //[end] TODO: to be removed
}
export const useMutationCustomerIndicator = () => {
  /**
   * use axios cancelToken logic
   *  for the work around of hoc/RequireAuth double load issue.
   */
  // let cancelToken: CancelTokenSource | undefined
  // apiClient.interceptors.request.use((req) => {
  //   if (cancelToken) cancelToken.cancel()

  //   // Create a new cancellation token
  //   cancelToken = axios.CancelToken.source()
  //   req.cancelToken = cancelToken.token

  //   return req
  // })
  // // use a private customerApiClient so the cancelToken logic do not affect other api calls
  // const _customerApiClient = new CustomerApi(BaseApiConfig, apiClient)
  /**
   * [end] use axios cancelToken logic
   */

  return useMutation({
    mutationFn: async (data: UseMutationCustomerIndicator) => {
      const res = await customerApiClient.getCustomerIndicator({
        ...data,
      })
      return res.data
    },
    mutationKey: [CUSTOMER_INDICATOR],
  })
}

export interface UseMutationSubmitEcddForm extends CustomerEcddSubmitReqDto {}
export const useMutationSubmitEcddForm = () => {
  return useMutation({
    mutationFn: async (data: UseMutationSubmitEcddForm) => {
      const res = await customerApiClient.submitEcddForm({
        customerEcddSubmitReqDto: {
          ...data,
        },
      })

      return res.data
    },
    mutationKey: [SUBMIT_ECDD_FORM],
  })
}

export interface UseQueryCustomerProfileRes extends CustomerProfileResDto {}
export const useQueryCustomerProfile = () => {
  return useQuery({
    queryFn: async () => {
      const res = await customerApiClient.getCustomerProfile()
      return res.data as UseQueryCustomerProfileRes
    },
    queryKey: [CUSTOMER_PROFILE_FETCH],
  })
}

export enum CustomerProfileTypeEnum {
  EPF = 'epf',
  EKYC = 'EKYC',
  EXISTING = 'existing',
}
export interface UseMutationCustomerProfile
  extends CustomerOnboardSubmitReqDto {}
export const useMutationCustomerProfile = (type?: CustomerProfileTypeEnum) => {
  const _epfToken = useSelector(selectEpfToken)
  const _ekycRefNo = useSelector(selectEkycRefNo)
  const xEpfToken =
    type === CustomerProfileTypeEnum.EPF && !!_epfToken ? _epfToken : undefined
  const xEkycRefno =
    type === CustomerProfileTypeEnum.EKYC && !!_ekycRefNo
      ? _ekycRefNo
      : undefined

  return useMutation({
    mutationFn: async (data: UseMutationCustomerProfile) => {
      const res = await customerApiClient.submitCustomerProfileFromOnboarding({
        xEpfToken,
        xEkycRefno,
        customerOnboardSubmitReqDto: data,
      })
      return res.data
    },
    mutationKey: [CUSTOMER_PROFILE_SETUP, _epfToken, xEkycRefno],
  })
}

export interface UseQueryCustomerMetadataRes extends CustomerMetadataResDto {}
export const useQueryCustomerMetadata = ({
  enabled = true,
}: {
  enabled?: boolean
}) => {
  return useQuery({
    queryFn: async () => {
      const res = await customerApiClient.getCustomerMetadata()
      return res.data as UseQueryCustomerMetadataRes
    },
    enabled,
    queryKey: [CUSTOMER_METADATA_FETCH],
  })
}

export interface UseQueryCustomerPortfolioRes extends PortfolioSummaryResDto {}
export const useQueryCustomerPortfolio = () => {
  return useQuery({
    queryFn: async () => {
      const res = await customerApiClient.getCustomerPortfolio()
      return res.data as UseQueryCustomerPortfolioRes
    },
    queryKey: [CUSTOMER_PORTFOLIO_FETCH],
  })
}

export const useMutationEditCustomerProfileEmploymentInfo = () => {
  return useMutation({
    mutationFn: async (data: CustomerProfileEditEmploymentInfoReqDto) => {
      const res = await customerApiClient.editCustomerProfileEmploymentInfo({
        customerProfileEditEmploymentInfoReqDto: {
          ...data,
        },
      })
      return res.data
    },
    mutationKey: [EDIT_EMPLOYMENT_INFO],
  })
}

export const useMutationEditCustomerProfilePersonalInfo = () => {
  return useMutation({
    mutationFn: async (data: CustomerProfileEditPersonalInfoReqDto) => {
      const res = await customerApiClient.editCustomerProfilePersonalInfo({
        customerProfileEditPersonalInfoReqDto: {
          ...data,
        },
      })

      return res.data
    },
    mutationKey: [EDIT_PERSONAL_INFO],
  })
}

export const useMutationEditCustomerProfileContactInfo = () => {
  return useMutation({
    mutationFn: async (data: CustomerProfileEditContactInfoReqDto) => {
      const res = await customerApiClient.editCustomerProfileContactInfo({
        customerProfileEditContactInfoReqDto: {
          ...data,
        },
      })
      return res.data
    },
    mutationKey: [EDIT_CONTACT_INFO],
  })
}

export const useMutationConfirmCustomerProfileEditRequest = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (data: OtpVerifiedReqDto) => {
      const res = await customerApiClient.confirmCustomerProfileEditRequest({
        otpVerifiedReqDto: { ...data },
      })
      return res.data
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CUSTOMER_PROFILE_FETCH],
        exact: true,
      })
    },
    mutationKey: [CONFIRM_EDIT_PROFILE_INFO],
  })
}
