import { useQueryBankList, useQueryFundList } from '@/api/commonApi'
import { useMutationQueryRedemptionOrderInstructionData } from '@/api/orderApi'
import { useMemo } from 'react'

export const useInitData = () => {
  const {
    mutate,
    status: mutateStatus,
    isLoading: mutateIsLoading,
    data: mutatedData,
    error: mutatedError,
  } = useMutationQueryRedemptionOrderInstructionData()

  const enabled = useMemo(() => mutateStatus === 'success', [mutateStatus])

  const {
    data: fundList,
    isLoading: isLoadingFL,
    isError: isErrorFL,
  } = useQueryFundList({ enabled })

  const {
    data: bankList,
    isLoading: isLoadingBL,
    isError: isErrorBL,
  } = useQueryBankList({ enabled })

  const isLoading = useMemo(
    () => mutateIsLoading || isLoadingFL || isLoadingBL,
    [mutateIsLoading, isLoadingFL, isLoadingBL]
  )

  const isError = useMemo(() => isErrorFL || isErrorBL, [isErrorFL, isErrorBL])

  return {
    mutate,
    mutateStatus,
    mutateIsLoading,
    mutatedData,
    mutatedError,
    fundList,
    bankList,
    isLoading,
    isError,
  }
}
