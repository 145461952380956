import { forwardRef } from 'react'

export const InviewportChecker = forwardRef<
  HTMLDivElement,
  { isOffset: boolean }
>(({ isOffset }, ref) => {
  return (
    <div className='col-span-full relative'>
      <div
        className={`absolute w-full ${isOffset ? '-top-[500px]' : ''}`}
        ref={ref}
      />
    </div>
  )
})

export default InviewportChecker
