import {
  FormLabel,
  FormErrorMessage,
  PropmoteCodeInput,
  PropmoteCodeInputProps,
  useModal,
} from '@pimy-b2cweb/frontend-lib'
import {
  useState,
  useEffect,
  useRef,
  forwardRef,
  memo,
  useImperativeHandle,
} from 'react'
import { useTranslation } from 'react-i18next'
import { ControllerRenderProps, FieldError } from 'react-hook-form'
import { useMutationVerifyCampaignCode } from '@/api/commonApi'
import TwoSideText from '@/components/TwoSideText'
import useSetUserNotIdle from '@/hooks/useSetUserNotIdle'
import { getErrorResponseCode } from '@/utils'
import CampaignPromoModal, {
  CampaignPromoModalProps,
} from './CampaignPromoModal'
import { CampaignCode } from '@/hooks/useInvestmentReducer'

export interface CampaignCodeCompProps {
  value?: CampaignCode
  error?: FieldError
  disabled?: boolean
  onChange: ControllerRenderProps['onChange']
  fundCodes: CampaignPromoModalProps['fundCodes']
  subaccountNo: CampaignPromoModalProps['subaccountNo']
}

export type CampaignCodeRef = {
  hasUnappliedCode: () => boolean
}

export const CampaignCodeComp = memo(
  forwardRef<CampaignCodeRef, CampaignCodeCompProps>(
    ({ value, error, disabled, onChange, fundCodes, subaccountNo }, ref) => {
      const setUserNotIdle = useSetUserNotIdle()
      const promoteCodeRef = useRef<HTMLDivElement | null>(null)
      useImperativeHandle(ref, () => ({
        hasUnappliedCode: () => !!userInputCode,
      }))

      const { t } = useTranslation(['cashInPage', 'common'])

      const [selectedCode, setSelectedCode] = useState<
        CampaignCode | undefined
      >(undefined)
      useEffect(() => {
        if (value !== selectedCode) setSelectedCode(value)
      }, [value])
      useEffect(() => {
        if (value !== selectedCode) onChange(selectedCode)
      }, [selectedCode])

      const [userInputCode, setUserInputCode] = useState<string>('')
      const [inputCodeError, setInputCodeError] = useState<string>('')

      const {
        mutate,
        isLoading: mutateIsLoading,
        status: mutateStatus,
        data: mutatedData,
        error: mutatedError,
      } = useMutationVerifyCampaignCode()

      const handleChange: PropmoteCodeInputProps['onChange'] = (e) => {
        if (!!e.target.value && !/^[a-zA-Z0-9]+$/.test(e.target.value)) return
        setUserInputCode(e.target.value)
      }
      const handleEnter: PropmoteCodeInputProps['onKeyDown'] = ({ key }) => {
        if (key === 'Enter') handleApplyCode(userInputCode)
      }

      const isDisabled = mutateIsLoading || disabled

      const handleReset = () => setSelectedCode(undefined)
      const handleApplyCode = async (code?: string) => {
        if (!code || isDisabled) return
        mutate({
          campaignCode: code,
          fundCodes,
          subaccountNo,
        })
        setUserNotIdle()
      }

      const { modalOpen, ...useModalRest } = useModal()

      useEffect(() => {
        if (mutateStatus === 'error') {
          setInputCodeError(getErrorResponseCode(mutatedError))
          return
        }

        if (mutateStatus === 'success' && !!mutatedData) {
          handleSetSelectedCode(mutatedData)
        }
      }, [mutatedData, mutateStatus, mutatedError])

      const handleSetSelectedCode = (data: CampaignCode) => {
        setInputCodeError('')
        setUserInputCode('')
        setSelectedCode(data)
      }

      return (
        <>
          <FormLabel
            id='campaignCodeInout'
            label={
              <TwoSideText
                left={t('promo-code')}
                right={
                  <span
                    className={`clickable-text !font-normal ${
                      isDisabled ? 'disabled' : ''
                    }`}
                    onClick={() => !isDisabled && modalOpen()}
                  >
                    {t('view-available-promotion')}
                  </span>
                }
              />
            }
            isError={!!error}
            ref={promoteCodeRef}
            className={!selectedCode ? '' : '[&>label]:!mb-4'}
          >
            {!selectedCode ? (
              <PropmoteCodeInput
                fullWidth
                error={!!inputCodeError || !!error}
                helperText={inputCodeError}
                disabled={isDisabled}
                buttonOnClick={() => handleApplyCode(userInputCode)}
                buttonChildren={t('Apply', { ns: 'common' })}
                className='mb-4'
                onChange={handleChange}
                value={userInputCode}
                isLoading={mutateIsLoading}
                onKeyDown={handleEnter}
                id='campaignCodeInout'
              />
            ) : (
              <div className='flex items-center gap-2 mb-4 pl-[1.5px]'>
                <span
                  className={`whitespace-normal ${
                    !error ? 'text-pi-utility-green' : 'text-pi-utility-red'
                  } text-sm font-bold grow`}
                >
                  {selectedCode.name}
                </span>
                <button
                  className='px-4 py-2 rounded border border-solid border-pi-true-blue capitalize text-pi-true-blue bg-white hover:bg-pi-gray-6 cursor-pointer'
                  onClick={() => handleReset()}
                  disabled={isDisabled}
                >
                  {t('Cancel', { ns: 'common' })}
                </button>
              </div>
            )}
            <FormErrorMessage error={error} />
          </FormLabel>
          <CampaignPromoModal
            handleSetSelectedCode={handleSetSelectedCode}
            appliedCode={selectedCode?.campaignCode}
            fundCodes={fundCodes}
            subaccountNo={subaccountNo}
            {...useModalRest}
          />
        </>
      )
    }
  )
)

export default CampaignCodeComp
