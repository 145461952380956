import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import type {
  Dispatch,
  FunctionComponent,
  SVGProps,
  SetStateAction,
} from 'react'
import useHandleSignOut from '@/hooks/useHandleSignOut'
import { ReactComponent as LogoutIco } from '../assets/log-out.svg'
import { ReactComponent as GiftIco } from '../assets/gift-ico.svg'
import NavItem from '../NavItem'
import CopyToClipboard from '@/components/CopyToClipboard'

export interface NavbarNavItem {
  label: string
  path: string
  Icon?: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>
  isNew?: boolean
  onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

const Navbar = ({
  navOpen,
  setNavOpen,
  NavItems,
  referralCode,
}: {
  navOpen: boolean
  setNavOpen: Dispatch<SetStateAction<boolean>>
  NavItems: NavbarNavItem[]
  referralCode?: string
}) => {
  const { t } = useTranslation('header')
  const handleSignOut = useHandleSignOut()

  return (
    <nav
      className={`${
        navOpen ? '' : 'hidden'
      } z-50 w-full min-h-[calc(100svh-80px)] bg-pi-navy-blue flex flex-col px-4 py-10 fixed md:!hidden`}
    >
      {/* TODO: replace referral code with actual code from API */}
      {!!referralCode && (
        <div className='flex flex-row justify-between items-center text-white text-base -mt-10 -mx-4 p-4 mb-10 bg-pi-principal-blue'>
          <div className='flex flex-row items-center gap-2'>
            <div className='font-bold'>
              {`${t('referral-code')}:`}{' '}
              <span className='font-normal'>{referralCode}</span>
            </div>
            <GiftIco className='w-6 h-6' />
          </div>
          <CopyToClipboard text={referralCode} />
        </div>
      )}
      <div className='flex flex-col gap-10' onClick={() => setNavOpen(false)}>
        {NavItems.map(({ path, label, Icon = '', isNew = false, onClick }) => (
          <NavLink
            key={label}
            to={path}
            onClick={onClick}
            className={({ isActive }) =>
              `flex flex-row justify-start items-center gap-4 text-white hover:!text-pi-chartreuse hover:no-underline select-none ${
                isActive ? '!text-pi-chartreuse pointer-events-none' : ''
              }`
            }
          >
            {({ isActive }) => (
              <>
                <Icon
                  className={`${
                    isActive
                      ? '[&>g>path]:stroke-pi-chartreuse [&>path]:stroke-pi-chartreuse'
                      : ''
                  }`}
                />
                <NavItem label={t(label)} isNew={isNew} />
              </>
            )}
          </NavLink>
        ))}
      </div>
      <div
        onClick={handleSignOut}
        className='flex flex-row justify-start gap-4 text-white hover:!text-pi-chartreuse hover:no-underline cursor-pointer select-none mt-auto'
      >
        <LogoutIco className=' w-6 h-6 md:hidden' />
        {t('log-out')}
      </div>
    </nav>
  )
}

export default Navbar
