import { createSlice } from '@reduxjs/toolkit'

export interface userStatusModalSessionState {
  isIsafModalOpen: boolean
  isEcddModalOpen: boolean
}

const initialState: userStatusModalSessionState = {
  isIsafModalOpen: false,
  isEcddModalOpen: false,
}

export const userStatusModalSessionSlice = createSlice({
  name: 'userStatusModal',
  initialState,
  reducers: {
    sessionResetUserStatusModal: (state) => {
      state.isIsafModalOpen = initialState.isIsafModalOpen
      state.isEcddModalOpen = initialState.isEcddModalOpen
    },

    sessionOpenIsafModal: (state) => {
      state.isIsafModalOpen = true
      state.isEcddModalOpen = false
    },

    sessionOpenEcddModal: (state) => {
      state.isIsafModalOpen = false
      state.isEcddModalOpen = true
    },
  },
})

//action export
export const {
  sessionResetUserStatusModal,
  sessionOpenIsafModal,
  sessionOpenEcddModal,
} = userStatusModalSessionSlice.actions

export default userStatusModalSessionSlice.reducer
