import {
  RedemptionOrderInstructionQueryResDto,
  RedemptionOrderReqDto,
  WrapperTypeEnum,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useReducer } from 'react'

export interface RedemptionOrderState
  extends Omit<RedemptionOrderReqDto, 'langCode' | 'wrapperType'>,
    Pick<RedemptionOrderInstructionQueryResDto, 'subaccountStatus'> {
  wrapperType?: WrapperTypeEnum
}

export interface SetRedemptionFundsProps
  extends Required<Pick<RedemptionOrderState, 'funds' | 'amount'>> {}

export interface SetBankDetailsProps
  extends Required<Pick<RedemptionOrderState, 'bankAccountNo' | 'bankCode'>> {}

export interface SetRedemptionReasonProps
  extends Required<Pick<RedemptionOrderState, 'redemptionReason'>> {}

enum RedemptionOrderActionType {
  SET = 'set',
  SET_FUNDS = 'set_funds',
  SET_BANK_DETAILS = 'set_bank_details',
  SET_REDEMPTION_REASON = 'set_redemption_reason',
}

export interface RedemptionOrderAction {
  type: RedemptionOrderActionType
  payload?: Partial<RedemptionOrderState>
}

const initialState: RedemptionOrderState = {
  wrapperType: undefined,
  productId: undefined,
  subaccountNo: '',
  funds: [],
  amount: 0,
  ccy: 'MYR',
  bankCode: '',
  bankAccountNo: '',
  redemptionReason: '',
  subaccountStatus: undefined,
}

export const useRedemptionOrderReducer = () => {
  const [redemptionOrder, dispatch] = useReducer(
    redemptionOrderReducer,
    initialState
  )

  const setRedemptionOrder = (data: Partial<RedemptionOrderState>) => {
    dispatch({ type: RedemptionOrderActionType.SET, payload: data })
  }

  const setRedemptionFunds = (data: SetRedemptionFundsProps) => {
    dispatch({ type: RedemptionOrderActionType.SET_FUNDS, payload: data })
  }

  const setBankDetails = (data: SetBankDetailsProps) => {
    dispatch({
      type: RedemptionOrderActionType.SET_BANK_DETAILS,
      payload: data,
    })
  }

  const setRedemptionReason = (data: SetRedemptionReasonProps) => {
    dispatch({
      type: RedemptionOrderActionType.SET_REDEMPTION_REASON,
      payload: data,
    })
  }

  return {
    redemptionOrder,
    dispatch,
    setRedemptionOrder,
    setRedemptionFunds,
    setBankDetails,
    setRedemptionReason,
  }
}

const redemptionOrderReducer = (
  state: RedemptionOrderState,
  action: RedemptionOrderAction
) => {
  switch (action.type) {
    case RedemptionOrderActionType.SET:
      return {
        ...state,
        wrapperType: action.payload?.wrapperType,
        productId: action.payload?.productId,
        subaccountNo: action.payload?.subaccountNo || '',
        funds: action.payload?.funds || [],
        amount: action.payload?.amount || 0,
        ccy: action.payload?.ccy || 'MYR',
        bankCode: action.payload?.bankCode || '',
        bankAccountNo: action.payload?.bankAccountNo || '',
        redemptionReason: action.payload?.redemptionReason || '',
        subaccountStatus: action.payload?.subaccountStatus,
      }
    case RedemptionOrderActionType.SET_FUNDS:
      return {
        ...state,
        funds: action.payload?.funds || [],
        amount: action.payload?.amount || 0,
      }
    case RedemptionOrderActionType.SET_BANK_DETAILS:
      return {
        ...state,
        bankCode: action.payload?.bankCode || '',
        bankAccountNo: action.payload?.bankAccountNo || '',
      }
    case RedemptionOrderActionType.SET_REDEMPTION_REASON:
      return {
        ...state,
        redemptionReason: action.payload?.redemptionReason || '',
      }
    default:
      return state
  }
}
