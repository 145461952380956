import { useCallback, useEffect, useMemo, useState } from 'react'

export const useIsInViewport = <T extends HTMLElement>() => {
  const [isInViewport, setIsInViewport] = useState(false)
  const [element, setElement] = useState<T>()

  const setRef = useCallback((node: T) => setElement(node), [])

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsInViewport(entry.isIntersecting)
      ),
    []
  )

  useEffect(() => {
    if (!!element) {
      observer.observe(element)
    }
    return () => observer.disconnect()
  }, [element])

  return { setRef, isInViewport }
}

export default useIsInViewport
