import {
  CommonApi,
  OtpVerifyReqDto,
  FundInformation,
  BankInformation,
  CommonApiQueryCampaignListRequestParameters,
  CommonApiQueryCampaignCodeRequestParameters,
} from '@pimy-b2cweb/apiclient-b2cweb-r2'
import { useSelector } from 'react-redux'
import { useMutation, useQuery } from '@tanstack/react-query'
import i18n from '@/i18n'
import {
  RESEND_OTP,
  VERIFY_OTP,
  FETCH_LOV,
  GET_PROMO_CODE,
  VERIFY_PROMO_CODE,
  VERIFY_AGENT_CODE,
  GET_FUNDS,
  GET_BANKS,
  GET_POSTAL_LIST,
  VERIFY_REFERRAL_CODE,
} from '@/constants/apiKeys'
import { selectAuthSessionState } from '@/stores/auth.selectors'
import { apiLangCode } from '@/utils'
import { BaseApiConfig, apiClient } from './api-config'

//TODO: to be removed
export const MOCK_FUNDS: FundInformation[] = [
  {
    code: 'MYU1000AA007',
    id: 'MYU1000AA007',
    name: 'Principal Islamic Asia Pacific Dynamic Equity Fund ',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: true,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [],
    riskLevel: 3,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: false,
    isNewFund: false,
  },
  {
    code: 'MYU1000FE008',
    id: 'MYU1000FE008',
    name: 'Principal Global Dividend Maximiser Fund (Class MYR)',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: false,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [],
    riskLevel: 3,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: true,
    isNewFund: false,
  },
  {
    code: 'MYU1000FL003',
    id: 'MYU1000FL003',
    name: 'CIMB Islamic Corporate Deposit Fund 2  ',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: true,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [],
    riskLevel: 3,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: false,
    isNewFund: false,
  },
  {
    code: 'MYU1000FZ003',
    id: 'MYU1000FZ003',
    name: 'Principal Asia Pacific Dynamic Mixed Asset Fund (Class MYR) ',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: false,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [],
    riskLevel: 3,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: true,
    isNewFund: false,
  },
  {
    code: 'MYU1000HF999',
    id: 'MYU1000HF999',
    name: 'Principal Asia Pacific Dynamic Mixed Asset Fund (Class MYR)',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: true,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [
      {
        detail: 'average Total Returns (Since inception until 30 Nov 2023)',
        index: 1,
      },
      {
        detail: 'Global markets (MYR)',
        index: 2,
      },
      {
        detail:
          'Top Holdings: BNP Paribas, Comgest Growth Europe, Tencent, Alibaba and more',
        index: 3,
      },
      {
        detail: 'Equity',
        index: 4,
      },
    ],
    riskLevel: 3,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: false,
    isNewFund: false,
  },
  {
    code: 'MYU1000HF9991',
    id: 'MYU1000HF9991',
    name: 'Principal Asia Pacific Conservative Fund (Class MYR)',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: true,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [
      {
        detail: 'average Total Returns (Since inception until 30 Nov 2023)',
        index: 1,
      },
      {
        detail: 'Global markets (MYR)',
        index: 2,
      },
      {
        detail:
          'Top Holdings: BNP Paribas, Comgest Growth Europe, Tencent, Alibaba and more',
        index: 3,
      },
      {
        detail: 'Equity',
        index: 4,
      },
    ],
    riskLevel: 1,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: true,
    isNewFund: true,
  },
  {
    code: 'MYU1000HF9992',
    id: 'MYU1000HF9992',
    name: 'Principal Asia Pacific Moderately Conservative Asset Fund (Class MYR)',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: true,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [
      {
        detail: 'average Total Returns (Since inception until 30 Nov 2023)',
        index: 1,
      },
      {
        detail: 'Global markets (MYR)',
        index: 2,
      },
      {
        detail:
          'Top Holdings: BNP Paribas, Comgest Growth Europe, Tencent, Alibaba and more',
        index: 3,
      },
      {
        detail: 'Equity',
        index: 4,
      },
    ],
    riskLevel: 2,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: false,
    isNewFund: true,
  },
  {
    code: 'MYU1000HF9994',
    id: 'MYU1000HF9994',
    name: 'Principal Asia Pacific Moderately Aggresive Fund (Class MYR)',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: true,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [
      {
        detail: 'average Total Returns (Since inception until 30 Nov 2023)',
        index: 1,
      },
      {
        detail: 'Global markets (MYR)',
        index: 2,
      },
      {
        detail:
          'Top Holdings: BNP Paribas, Comgest Growth Europe, Tencent, Alibaba and more',
        index: 3,
      },
      {
        detail: 'Equity',
        index: 4,
      },
    ],
    riskLevel: 4,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: true,
    isNewFund: true,
  },
  {
    code: 'MYU1000HF9995',
    id: 'MYU1000HF9995',
    name: 'Principal Asia Pacific Aggresive Fund (Class MYR)',
    assetClass: 'Mixed',
    fundAer: 1.45,
    fundBestCase: 9.83,
    fundWorstCase: 1.57,
    fundStatus: 't',
    fundArticleInitial: '1',
    effectiveDate: '2023-12-19T00:00:00.000Z',
    fundImageUrl:
      'https://www.principal.com.my/sites/default/files/media/Online_Investment_Hero_Optimized%20image%201920x600.jpg',
    maturityDate: '2032-03-03T16:00:00.000Z',
    fundConfigurationBuy: '1',
    fundConfigurationTopUp: '1',
    fundConfigurationSwitch: '1',
    fundConfigurationRedeem: '1',
    currency: 'MYR',
    custodianBank: 'HSBC',
    custFeePerc: 0,
    mgmtFeePerc: 0,
    subsFeePerc: 0,
    acctNo: '123456',
    acctName: 'Principal',
    minSubsAmt: 10,

    minSwitchUnit: 0,
    minSwitchAmt: 0,
    minRemUnit: 0,
    minRedmpAmt: 0,
    minRedmpUnit: 0,
    lastUpdated: '2023-10-17T00:00:00.000Z',

    isSyariahFund: true,
    lockinPeriod: '',
    threeAnnualReturn: '0%',
    annualReturn: '12',
    minDiv: '',
    maxDiv: '',
    minVol: '',
    maxVol: '',
    award: '',
    recommend: '',
    prospectusUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Master_Prospectus_Islamic_Funds_PROP.pdf',
    leafletUrl: '',
    reportUrl: '',
    fundInfoUrl: 'https://www.principal.com.my/en/ibf-d/mv',
    salesFeePerc: 1.5,

    details: [
      {
        detail: 'average Total Returns (Since inception until 30 Nov 2023)',
        index: 1,
      },
      {
        detail: 'Global markets (MYR)',
        index: 2,
      },
      {
        detail:
          'Top Holdings: BNP Paribas, Comgest Growth Europe, Tencent, Alibaba and more',
        index: 3,
      },
      {
        detail: 'Equity',
        index: 4,
      },
    ],
    riskLevel: 5,
    factSheetUrl: 'https://google.com',
    highlightUrl:
      'https://www.principal.com.my/sites/default/files/fund-documents/Malaysia%20Site/en_Principal_Islamic_Lifetime_Balanced_Fund_D_PHS.pdf',
    isEPFFund: false,
    isNewFund: true,
  },
]

//[end]TODO: to be removed

const commonApiClient = new CommonApi(BaseApiConfig, apiClient)

export interface UseMutationVerifyOtp extends Pick<OtpVerifyReqDto, 'code'> {}
export const useMutationVerifyOtp = () => {
  const { sessionToken, challengeId } = useSelector(selectAuthSessionState)

  return useMutation({
    mutationFn: async ({ ...data }: UseMutationVerifyOtp) => {
      const res = await commonApiClient.verifyOtp({
        xSessionToken: sessionToken as string,
        otpVerifyReqDto: {
          challengeId: challengeId as string,
          ...data,
        },
      })
      return res.data
    },
    mutationKey: [VERIFY_OTP],
  })
}

export const useMutationResendOtp = () => {
  const { sessionToken, challengeId } = useSelector(selectAuthSessionState)
  const langCode = apiLangCode(i18n.language)

  return useMutation({
    mutationFn: async () => {
      const res = await commonApiClient.resendOtp({
        xSessionToken: sessionToken as string,
        otpResendReqDto: {
          challengeId: challengeId as string,
          langCode,
        },
      })
      return res.data
    },
    mutationKey: [RESEND_OTP, langCode],
  })
}

export interface UseMutationFetchLov {
  screen: ScreenTypeEnum
}
export enum ScreenTypeEnum {
  PROFILE_FORM = 'profile_form',
  ECDD_FORM = 'ecdd_form',
  REDEMPTION_SURVEY = 'redemption_survey',
  BANK_ACCOUNT_FORM = 'bank_account_form',
}
export enum ProfileFormLovKeysEnum {
  AnnualIncome = 'annual_income',
  Country = 'country',
  PurposeOfInvestment = 'purpose_of_investment',
  NatureOfBusiness = 'nature_of_business',
  Occupation = 'occupation',
  Race = 'race',
  Gender = 'gender',
  SourceOfFunds = 'source_of_funds',
  State = 'state',
  MaritalStatus = 'martial_status',
}
export enum EcddFormLovKeysEnum {
  EstimatedNetWorth = 'estimated_net_worth',
  NumberOfTransaction = 'number_of_transaction',
  SourceOfIncome = 'source_of_income',
  SourceOfWealth = 'source_of_wealth',
  SupportingDocument = 'supporting_document',
  VolumeOfTransaction = 'volume_of_transaction',
}
export enum RedemptionSurveyKeysEnum {
  redemptionReason = 'redemption_reason',
}
export enum BankAccountFormKeysEnum {
  BankName = 'bank_name',
}

export const getLovKey = (screen: ScreenTypeEnum) => {
  switch (screen) {
    case ScreenTypeEnum.PROFILE_FORM:
      return Object.values(ProfileFormLovKeysEnum)
    case ScreenTypeEnum.ECDD_FORM:
      return Object.values(EcddFormLovKeysEnum)
    case ScreenTypeEnum.REDEMPTION_SURVEY:
      return Object.values(RedemptionSurveyKeysEnum)
    case ScreenTypeEnum.BANK_ACCOUNT_FORM:
      return Object.values(BankAccountFormKeysEnum)
  }
}

export const useMutationFetchLov = ({ screen }: UseMutationFetchLov) => {
  const langCode = apiLangCode(i18n.language)
  const keys = getLovKey(screen)
  return useMutation({
    mutationFn: async () => {
      const { data: body } = await commonApiClient.groupedQuery({
        lOVMultipleGroupedQueryReqDto: {
          keys,
          langCode,
        },
      })
      if (body.code !== 'ok') throw new Error('unknown_error')

      return body.groups
    },
    mutationKey: [FETCH_LOV, langCode, keys],
  })
}

export interface UseQueryGetCampaignCode
  extends Pick<
    CommonApiQueryCampaignListRequestParameters,
    'fundCodes' | 'subaccountNo'
  > {}

export const useQueryGetCampaignCode = ({
  fundCodes,
  subaccountNo,
}: UseQueryGetCampaignCode) => {
  const langCode = apiLangCode(i18n.language)
  return useQuery({
    queryFn: async () => {
      const res = await commonApiClient.queryCampaignList({
        fundCodes,
        subaccountNo,
      })
      return res.data.campaigns
    },
    queryKey: [GET_PROMO_CODE, langCode, fundCodes, subaccountNo],
  })
}

export const useMutationVerifyCampaignCode = () => {
  const langCode = apiLangCode(i18n.language)

  return useMutation({
    mutationFn: async ({
      campaignCode,
      fundCodes,
      subaccountNo,
    }: Pick<
      CommonApiQueryCampaignCodeRequestParameters,
      'campaignCode' | 'fundCodes' | 'subaccountNo'
    >) => {
      const res = await commonApiClient.queryCampaignCode({
        fundCodes,
        campaignCode,
        subaccountNo,
        langCode,
      })

      return res.data.campaign
    },
    mutationKey: [VERIFY_PROMO_CODE, langCode],
  })
}

export const useMutationVerifyAgentCode = () => {
  return useMutation({
    mutationFn: async (agentCode: string) => {
      const res = await commonApiClient.queryAgentByCode({
        agentCode,
      })

      return res.data.agent
    },
    mutationKey: [VERIFY_AGENT_CODE],
  })
}

export const useMutationVerifyReferralCode = () => {
  return useMutation({
    mutationFn: async (referralCode: string) => {
      const res = await commonApiClient.queryReferralByCode({
        referralCode,
      })
      return res.data.referral
    },
    mutationKey: [VERIFY_REFERRAL_CODE],
  })
}

export const useQueryFundList = ({ enabled = true }: { enabled?: boolean }) => {
  const langCode = apiLangCode(i18n.language)
  return useQuery({
    queryFn: async () => {
      const res = await commonApiClient.queryFundList({
        langCode,
      })
      return res.data.funds as FundInformation[]
    },
    enabled,
    queryKey: [GET_FUNDS, langCode],
  })
}

export const useQueryBankList = ({ enabled = true }: { enabled?: boolean }) => {
  return useQuery({
    queryFn: async () => {
      const res = await commonApiClient.queryBankList()
      return res.data.banks as BankInformation[]
    },
    enabled,
    queryKey: [GET_BANKS],
  })
}

export const useQueryPostalList = () => {
  return useQuery({
    queryFn: async () => {
      const res = await commonApiClient.queryPostalList()
      return res.data.postalList
    },
    queryKey: [GET_POSTAL_LIST],
  })
}
